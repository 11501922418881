import {post, service} from "@/utils/request";
import COS from "cos-js-sdk-v5";
import {randomFilename} from "@/utils/time";
import {bucketMap} from "@/utils/consts";

const uploadTokenExpire = 1800 // 秒

export const uploadTypeImage = 0    // 0为图片 1为文件 其他都为视频
export const uploadTypeVideo = 2
export const uploadTypeFile = 1

let cosMap = {
  image: cosByUploadType(uploadTypeImage),
  video: cosByUploadType(uploadTypeVideo)
}


export function cosByUploadType(uploadType) {
  // return new COS({ // 无token
  //   SecretId: 'AKIDskNnxsz2JUjphopsFl8YhDXidrrgcl6C',
  //   SecretKey: 'qQuJXNwdMo14EFetEjdu2nJmrpZgniFg',
  // });
  return new COS({
    getAuthorization: function (options, callback) {
      let ts = Math.floor(Date.parse(new Date()) / 1000)
      post('/upload/getTengToken', {id: uploadType}).then(res => {
        let data = res.data
        callback({
          TmpSecretId: data.tmpSecretId,
          TmpSecretKey: data.tmpSecretKey,
          XCosSecurityToken: data.sessionToken,
          StartTime: ts, // 时间戳，单位秒，如：1580000000
          ExpiredTime: ts + uploadTokenExpire, // 时间戳，单位秒，如：1580000900
        });
      }).catch(err =>{
        console.log('get authorization error:',err);
        callback({})
      })
    }
  })
}

export function canUpload(fileName){  // 判断文件上传的格式
  return post('/config/canUpload', {
    "fileName": fileName
  })
}


export function uploadimg(data) { // post Demo
  return service.request({
    method: 'post',
    url: '/upload/sendTxImage',
    headers: {'Content-Type': 'multipart/form-data'},
    data
  })
}


export function uploadvideo(data) {
  return service.request({
    method: 'post',
    url: '/upload/sendTxVideo',
    headers: {'Content-Type': 'multipart/form-data'},
    data
  })
}


// 下面这个函数用来上传图片和视频；使用el-upload结合cos-js-sdk-v5实现腾讯云cos文件上传
// 不过返回格式是其sdk定义好的，就一个location
// 如果上传视频，可以用上面这个函数，后端返回视频url和缩略图
// data使用方法：需要封装
					// const form = new FormData()
					// form.append('video',this.uploadimgs[0].raw)
					// const res = await uploadvideo(form)

export function directUpload(file, uploadType) {
  let cos = uploadType === uploadTypeImage ? cosMap.image : cosMap.video
  let bucket = uploadType === uploadTypeImage ? bucketMap.image : bucketMap.video

  return new Promise((resolve, reject) => {
    cos.putObject(
      {
        Bucket: bucket, /* 必须 */
        Region: "ap-shanghai",     /* 存储桶所在地域，必须字段 */
        Key: randomFilename(),              /* 必须 */
        StorageClass: 'STANDARD',
        Body: file, // 上传文件对象
        onProgress: function (progressData) {
          // 上传文件详情
          console.log('uploading:',progressData);
        }
      },
      function (err, data) {
        if (err) {
          reject(err)
        } else {
          resolve(data)
        }
      }
    );
  })
}

