import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './permission' // 引入权限控制
import './assets/css/animation.css'
import { Swipe, SwipeItem } from 'vant';
import 'vant/lib/index.css';
import lazyPlugin from 'vue3-lazy'
import MetaInfo from 'vue-meta-info'
import Image from "./components/Image/Image.vue";
import Video from '@/components/Image/Video.vue';
import './utils/China/China'; // 中国地图
import './assets/css/my-css.css'  // 公共css
import('@/assets/css/element-variables.scss')
import { noScrollTop,TanNoScrollTop } from './router/index';

// 路由跳转后返回到顶部
router.afterEach((to,from) => {
  if(to.path === '/searchDynamic'){ // 页面判断
    if(from.path === '/searchDynamic'){ // 页码判断
      if(from.query.page && to.query.page && to.query.page != from.query.page){
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
      else if(!from.query.page && to.query.page && to.query.page != 1){
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
      else return
    }
  }
  else if(to.path === '/business-vip'){
    if(from.query.offset && to.query.offset && to.query.offset != from.query.offset){
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
    else if(!from.query.offset && to.query.offset && to.query.offset != 1){
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
    return
  }
  else if(to.path === '/search-patent'){ // 页面判断
    if(from.path === '/search-patent'){ // 页码判断
      if(from.query.page && to.query.page && to.query.page != from.query.page){
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
      else if(!from.query.page && to.query.page && to.query.page != 1){
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
      else return
    }
  }
  else if(to.path === '/search-industry'){ // 页面判断
    if(from.path === '/search-industry'){ // 页码判断
      if(from.query.page && to.query.page && to.query.page != from.query.page){
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
      else if(!from.query.page && to.query.page && to.query.page != 1){
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
      else return
    }
  }
  else if(to.path === '/search-center'){ // 路由判断
    if(from.path === '/search-center'){ // 页码判断
      if(from.query.page && to.query.page && to.query.page != from.query.page){
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
      else if(from.query.offset && to.query.offset && to.query.offset != from.query.offset){
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
      else if(!from.query.page && to.query.page && to.query.page != 1){
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
      else if(!from.query.offset && to.query.offset && to.query.offset != 1){
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
      else if(from.query.type && to.query.type && from.query.type !== to.query.type){
        // 判断切换选项
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
      else return
    }
  }
  else if(to.path === '/enterpriseDynamic'){ // 页面判断
    if(from.path === '/enterpriseDynamic'){ // 页码判断
      if(from.query.page && to.query.page && to.query.page != from.query.page){
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
      else if(!from.query.page && to.query.page && to.query.page != 1){
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
      else return
    }
  }
  else if(to.path === '/search-company'){ // 页面判断
    if(from.path === '/search-company'){ // 页码判断
      if(from.query.page && to.query.page && to.query.page != from.query.page){
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
      else if(!from.query.page && to.query.page && to.query.page != 1){
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
      else return
    }
  }
  else if(to.path === '/search-demand'){ // 页面判断
    if(from.path === '/search-demand'){ // 页码判断
      if(from.query.page && to.query.page && to.query.page != from.query.page){
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
      else if(!from.query.page && to.query.page && to.query.page != 1){
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
      else return
    }
  }
  else if(to.path === '/incubator') {
    return
  }
  else if(to.path === '/user-center') return  // 不返回顶部
  else if(noScrollTop.includes(to.path)) return // 不用回到顶部
  else if(TanNoScrollTop.includes(to.path) && !to.query.resourceKey) return // 不用回到顶部
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
})

import {
  ElTable,
  ElTableColumn,
  ElDrawer,
  ElCollapse,
  ElCollapseItem,
  ElInfiniteScroll,
  ElLoading,
  ElMessage,
  ElEmpty,
  ElMessageBox,
  ElNotification,
  ElInput,
  ElButton,
  ElPagination,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElIcon,
  ElTabs,
  ElTabPane,
  ElForm,
  ElFormItem,
  ElSelect,
  ElSkeleton,
  ElSkeletonItem,
  ElOption,
  ElDatePicker,
  ElCard,
  ElDialog,
  ElUpload,
  ElProgress,
  ElRadio,
  ElCascader,
  ElPopover,
  ElCheckbox,
  ElCheckboxButton,
  ElCheckboxGroup, ElTag, ElBadge, ElImage, ElImageViewer,
  ElCarousel, ElCarouselItem,
  ElCollapseTransition,
  ElMenu,ElMenuItem,ElSubmenu,ElMenuItemGroup,  // 侧栏菜单
  ElDropdown,ElDropdownMenu,ElDropdownItem,
  ElSwitch,
  ElSlider
} from 'element-plus';
import 'element-plus/lib/theme-chalk/base.css'
// 解决页面抖动问题
ElDialog.props.lockScroll.default = false
const components = [
  ElTable,
  ElTableColumn,
  ElDrawer,
  ElCollapseTransition.name, ElCollapseTransition, // el-collapse 动画效果
  ElMenu,ElMenuItem,ElSubmenu,ElMenuItemGroup,   // 侧栏菜单
  ElInput,
  ElButton,
  ElPagination,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElIcon,
  ElTabs,
  ElTabPane,
  ElForm,
  ElEmpty,
  ElFormItem,
  ElSelect,
  ElSkeleton,
  ElSkeletonItem,
  ElOption,
  ElDatePicker,
  ElCard,
  ElDialog,
  ElUpload,
  ElProgress,
  ElCascader,
  ElRadio,
  ElPopover,
  ElCheckbox,
  ElCheckboxButton,
  ElCheckboxGroup,
  ElTag,
  ElBadge,
  ElImage,
  ElImageViewer,
  ElCarousel,
  ElCarouselItem,
  ElDropdown,ElDropdownMenu,ElDropdownItem,
  ElSwitch,
  ElCollapse,
  ElCollapseItem,
  ElSlider
]
const plugins = [
  ElInfiniteScroll,
  ElLoading,
  ElMessage,
  ElMessageBox,
  ElNotification,
]
const app = createApp(App)
// 按需自动注册引入 Element-plus组件  see：https://element-plus.gitee.io/#/zh-CN/component/quickstart
components.forEach(component => {
  app.component(component.name, component)
})



plugins.forEach(plugin => {
  app.use(plugin)
})
// app.config.errorHandler = function (err) {
//   // handle error
//   // `info` 是 Vue 特定的错误信息，比如错误所在的生命周期钩子
//   // 只在 2.2.0+ 可用
//   console.error('vue handler error:',err);
// }
app.component("Image",Image)  // 全局组件注册
app.component("Video",Video)
app.use(store)
app.use(router)
app.use(Swipe)
app.use(SwipeItem)
app.use(lazyPlugin,{})
// app.use(ElementPlus)
app.use(MetaInfo)

app.mount('#app')
