<template>
  <el-dialog title="实名认证" v-model="memberIsShow" :show-close="false" :close-on-press-escape="false" width="30%">
    <div class="text">如需使用路演功能，请先完成实名认证</div>
    <el-form ref="memberForm" label-width="80px" :model="memberForm">
      <el-form-item label="姓名">
        <el-input v-model="memberForm.realName"></el-input>
      </el-form-item>
      <el-form-item label="身份证">
        <el-input maxlength="18" v-model="memberForm.realIdCard"></el-input>
      </el-form-item>
    </el-form>
    <template v-slot:footer>
      <span class="dialog-footer">
        <el-button @click="closeWindow">取 消</el-button>
        <el-button type="primary" @click="submit" class="primary">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { realMemberApi } from "@/api/live";
import { ElMessage } from "element-plus";
export default {
  name: "RealMember",
  props: [],
  emits: ['isShow','closeWindow'],
  computed: {},
  watch: {},
  data() {
    return {
      memberIsShow: true, // 弹层是否展开
      memberForm: {},
    };
  },
  mounted() {},
  methods: {
    closeWindow(){
      this.memberIsShow = false
      this.$emit('closeWindow')
    },
    // 提交
    submit() {
      if(!this.memberForm.realName || this.memberForm.realName?.length === 0){
        ElMessage.warning('请输入姓名!')
        return
      }
      else if(!this.memberForm.realIdCard || this.memberForm.realIdCard?.length === 0){
        ElMessage.warning('请输入身份证!')
        return
      }
      const { realIdCard } = this.memberForm;
      // 加权因子
      var weight_factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      // 校验码
      var check_code = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"];

      var code = realIdCard + "";
      var last = realIdCard[17]; //最后一位

      var seventeen = code.substring(0, 17);

      // ISO 7064:1983.MOD 11-2
      // 判断最后一位校验码是否正确
      var arr = seventeen.split("");
      var len = arr.length;
      var num = 0;
      for (var i = 0; i < len; i++) {
        num = num + arr[i] * weight_factor[i];
      }

      // 获取余数
      var resisue = num % 11;
      var last_no = check_code[resisue];

      // 格式的正则
      // 正则思路
      /*
		    第一位不可能是0
		    第二位到第六位可以是0-9
		    第七位到第十位是年份，所以七八位为19或者20
		    十一位和十二位是月份，这两位是01-12之间的数值
		    十三位和十四位是日期，是从01-31之间的数值
		    十五，十六，十七都是数字0-9
		    十八位可能是数字0-9，也可能是X
		    */
      var idcard_patter =
        /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;

      // 判断格式是否正确
      var format = idcard_patter.test(realIdCard);

      // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
      // return last === last_no && format ? true : false;
      if(last == last_no && format){
        let memberId = JSON.parse(localStorage.getItem('userInfo'));
        this.memberForm.memberId = memberId.id
        realMemberApi(this.memberForm).then(res=>{
            this.$emit('isShow',res)
        }).catch((err)=>{
            this.$emit('isShow',err)
        })
      }else{
        ElMessage.warning('请输入正确的身份证号码')
        // setTimeout(()=>{
        // this.memberForm.realIdCard = ''
        // },500)
      }
      this.$emit('isShow')
    },
  },
};
</script>
<style lang="scss" scoped>
.text {
  margin-bottom: 20px;
}
.primary{
  background-color: $--color-theme !important;
  border: none;
}
:deep() input:focus {
  border: 1px solid $--color-theme !important;
}
</style>