<template>
  <real-member
    v-if="isShowDialog"
    @isShow="isShow"
    @closeWindow="closeWindow"
  ></real-member>
  <create-live-dialog
    v-if="showCreatliveDialog"
    @closeCreateLiveWindow="closeCreateLiveWindow"
    @openCreateLiveWindow="openCreateLiveWindow2"
  ></create-live-dialog>
  <div :class="{'dark-theme': showDark && darkThemeRoute.includes(routePath),'small-screen': smallScreen}" v-if="!isOutside">
    <div class="header" ref="header" :style="`margin-left: -${scrollLeft}px;`">
      <div class="content">
        <div class="left">
          科企岛——科技赋能产业 让技术创造价值
        </div>
        <div class="right">
          <div v-if="!isLogin" @click="$router.push('/login')" style="cursor: pointer;" class="login-button fs-14">登录 | 注册</div>
          <div v-if="isLogin" class="messages-container">
            <div class="nav-user-info flex-cc">
              <el-popover
                placement="bottom"
                :width="262"
                trigger="hover"
                :popper-class="'popper-class-user'"
                content="keqidao">
                <template #reference>
                  <div class="flex-cc">
                    <img
                      v-if="userAvatar"
                      class="avatar"
                      :src="staticPath + userAvatar"
                    />
                    <img
                      v-else
                      src="@/assets/images/avatar.jpg"
                      class="avatar"
                    />
                  </div>
                </template>
                <div class="self-menu" v-loading="loadingInfo">
                  <div class="user-info">
                    <img
                      v-if="userAvatar"
                      class="avatar"
                      :src="staticPath + userAvatar" />
                    <img v-else src="@/assets/images/avatar.jpg" class="avatar" />
                    <div class="user-name">{{ userName }}</div>
                    <div v-if="(levelFlag && isCustom) || (levelFlag && isNewVip) || (levelFlag && vipInfo.status + '' === '2') || levelFlag" class="fs-12 fc-fff" style="margin-top: 6px;">
                      <div>VIP会员到期日期：{{ viptime }}</div>
                    </div>
                    <div v-else class="fs-12 fc-fff" style="margin-top: 6px;">
                      <div @click="checkVipPage()">开通VIP</div>
                    </div>
                  </div>
                  <div class="buttons">
                    <!-- <div class="button flex-cc" @click="newEdit(0)">
                      <div class="flex-cc">
                        <img src="../assets/images/navbar/org-space.png" alt="" class="left-icon"/>
                        <div>完善数字科创中心</div>
                      </div>
                      <div class="flex-cc">
                        <div class="right-icon" style="width:16px; height:16px;"></div>
                      </div>
                    </div> -->
                    <div class="button flex-cc" @click="newEdit(1)">
                      <div class="flex-cc">
                        <img src="../assets/images/navbar/space.png" alt="" class="left-icon" />
                        <div>个人资料</div>
                      </div>
                      <div class="flex-cc">
                        <div class="right-icon" style="width:16px; height:16px;"></div>
                      </div>
                    </div>
                    <div class="button flex-cc" @click="myOrder">
                      <div class="flex-cc">
                        <img src="../assets/images/navbar/order.png" alt="" class="left-icon" style="height: 20px;" />
                        <div>我的订单</div>
                      </div>
                      <div class="flex-cc">
                        <div class="right-icon" style="width:16px; height:16px;"></div>
                      </div>
                    </div>
                    <div class="button" @click="customerService()" v-if="hasTim && notLive">
                      <div class="flex-cc">
                        <img src="../assets/images/navbar/custom.png" alt="" class="left-icon" />
                        <div>联系客服</div>
                      </div>
                      <div class="flex-cc">
                        <div class="right-icon" style="width:16px; height:16px;"></div>
                      </div>
                    </div>
                    <div class="button" @click="commitLogout()">
                      <div class="flex-cc">
                        <img src="../assets/images/navbar/logout.png" alt="" class="left-icon" />
                        <div>退出登陆</div>
                      </div>
                      <div class="flex-cc">
                        <div class="right-icon" style="width:16px; height:16px;"></div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="exit-login" @click="commitLogout()">
                    <div class="exit">退出登录</div>
                  </div> -->
                </div>
              </el-popover>
            </div>
          </div>
          <div class="left40" :class="cur == 1 ? 'colorBlue' : ''" @click="clickColor(1)" v-if="isLogin">
            {{workSpaceTitle()}}
          </div>
        </div>
      </div>
      <div class="new-line">
        <div class="flex-cc" style="width: 1200px;">
          <img class="logo" src="../assets/images/navbar/logo-new.png" title="科企岛-官网" @click="backToHome" />
          <div class="content">
            <div class="left flex-cc">
              <a class="new-text fs-14 fc-111 cursor-p hover-color" :class="{'active': routePath ==='/'}" style="" :href="`${kqdPath}`">首页</a>
              <!-- <a class="new-text fs-14 fc-111 cursor-p hover-color" :class="{'active': routePath ==='/information'}" target="_blank" :href="`${kqdPath}/information`">资讯</a> -->
              <!-- <a class="new-text fs-14 fc-111 cursor-p hover-color" :class="{'active': routePath ==='/technology'}" target="_blank" :href="`${kqdPath}/technology`">科技</a> -->
              <!-- <a class="new-text fs-14 fc-111 cursor-p hover-color" :class="{'active': routePath ==='/profundity'}" target="_blank" :href="`${kqdPath}/profundity`">深度</a> -->
              <!-- // TODO_Track: 进入创新赛道 @click="aTrack('e_tabbar_dynamic')"  -->
              <a class="new-text fs-14 fc-111 cursor-p hover-color" :class="{'active': routePath ==='/searchDynamic'}" target="_blank" href="/searchDynamic">创新赛道</a>
              <!-- // TODO_Track: 进入专家通 @click="aTrack('e_come_expert_search')"  -->
              <a class="new-text fs-14 fc-111 cursor-p hover-color" :class="{'active': routePath ==='/search'}" target="_blank" href="/search?keyword=&city=&school=&college=&title=&offset=1&searchType=1&analyzerType2=&searchName=0&type=" >专家通</a>
              <a class="new-text fs-14 fc-111 cursor-p hover-color" :class="{'active': ['/frontier','/fashion','/forum','/facility','/park','/equipment','/character','/AR'].includes(routePath)}" target="_blank" :href="`/frontier`">产业</a>
              <a class="new-text fs-14 fc-111 cursor-p hover-color" :class="{'active': ['/finance-capital','/commerce'].includes(routePath)}" target="_blank" :href="`/finance-capital`">投融资</a>
              <!-- <a class="new-text fs-14 fc-111 cursor-p hover-color" :class="{'active': routePath ==='/search-demand'}" target="_blank" href="/search-demand">需求库</a> -->
              <!-- <el-popover
                placement="bottom"
                :width="96"
                trigger="hover"
                :popper-class="'popper-class-publish'"
                content="解决方案">
                <template #reference>
                  <a class="new-text fs-14 fc-111 cursor-p hover-color" :class="{'active': ['/company','/project'].includes(routePath)}" target="_blank" href="/company">解决方案</a>
                </template>
                <div class="flex-dc-rc-cc publish-button-list">
                  <p class="fs-14 fc-333 hover-color" @click="newPublish('/company')">企业</p>
                  <p class="fs-14 fc-333 hover-color" @click="newPublish('/project')">政府或园区</p>
                </div>
              </el-popover> -->
              <a class="new-text fs-14 fc-111 cursor-p hover-color" :class="{'active': routePath ==='/vip'}" target="_blank" href="/vip">服务体系</a>
              <!-- // TODO_Track: 进入路演活动 @click="aTrack('e_tabbar_live')"  -->
              <a class="new-text fs-14 fc-111 cursor-p hover-color" :class="{'active': routePath ==='/live-list'}" target="_blank" href="/live-list">路演直播</a>
              <a class="new-text fs-14 fc-111 cursor-p hover-color" :class="{'active': routePath ==='/business-vip'}" target="_blank" href="/business-vip">商学院</a>
              <a class="new-text fs-14 fc-111 cursor-p hover-color" :class="{'active': routePath ==='/incubator-vip'}" target="_blank" href="/incubator-vip">创投通</a>
              <a class="new-text fs-14 fc-111 cursor-p hover-color"  target="_blank" href="https://utanjiao.keqidao.com" v-if="environment === 'uat'">探角</a>
              <a class="new-text fs-14 fc-111 cursor-p hover-color"  target="_blank" href="https://tanjiao.keqidao.com" v-else>探角</a>
              <!-- <a class="new-text fs-14 fc-111 cursor-p hover-color" :class="{'active': routePath ==='/ability-workbench'}" target="_blank" href="/ability-workbench">人才工作站</a> -->
            </div>
            <div class="right">
              <!-- 搜索按钮 -->
              <div class="flex-cc cursor-p search-button" @click="openSearch">
                <div class="icon"></div>
                <p class="fs-14 fc-111">搜索</p>
              </div>
              <!-- 发布按钮 -->
              <el-popover
                placement="bottom"
                :width="96"
                trigger="hover"
                :popper-class="'popper-class-publish'"
                @after-enter="showPublish"
                @after-leave="hidePublish"
                content="下载APP">
                <template #reference>
                  <div class="flex-rc-cc publish-button cursor-p fs-14 fc-111" :style="`${reverseAngel === -180 ? 'color: #2280FF;':''}`">
                    发布
                    <img src="../assets/images/navbar/down-icon.png" alt="" srcset="" style="width:16px;height:16px;margin-left:3px;" :style="`transform: rotateZ(${reverseAngel}deg)`" v-if="reverseAngel !== -180">
                    <img src="../assets/images/navbar/down-icon-hover.png" alt="" srcset="" style="width:16px;height:16px;margin-left:3px;" v-else>
                  </div>
                </template>
                <!-- 发布按钮 -->
                <div class="flex-dc-rc-cc publish-button-list">
                  <p class="fs-14 fc-333 hover-color" @click="newPublish('/postdemand')">发布需求</p>
                  <p class="fs-14 fc-333 hover-color" @click="newPublish('/postTech')">发布成果</p>
                  <p class="fs-14 fc-333 hover-color" @click="newPublish('/postdynamic')">发布动态</p>
                  <p class="fs-14 fc-333 hover-color" @click="openCreateLiveWindow">发布路演</p>
                  <p class="fs-14 fc-333 hover-color" @click="newPublish('/huiyi')">发布会议</p>
                </div>
              </el-popover>
              <!-- 消息按钮 -->
              <div style="position: relative;margin-left: 17px;" class="flex-cc hover-color new-message-button" v-if="isLogin && hasTim" @click="showIMWindow()">
                <div v-if="!imWindowVisible" style="width: 16px;height: 18px;margin-right:3px;" class="un-show-message" v-loading="messagePending"></div>
                <!-- 透明度：isOpacity -->
                <div v-else-if="imWindowVisible" style="width: 16px;height: 18px;margin-right:3px;" class="show-message" v-loading="messagePending">
                </div>
                <div
                  v-if="unReadMessages > 0 && isLogin"
                  class="unread-message"
                  :class="{
                    over10: unReadMessages >= 10,
                    over99: unReadMessages > 99,
                  }">
                  {{
                    unReadMessages > 99
                      ? "99+"
                      : unReadMessages
                  }}
                </div>
                <div class="word">消息</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import CreateLiveDialog from "@/components/live/CreateLiveDialog";
import { mapGetters } from "vuex";
import { getToken, checkVip } from "@/utils/auth";
import { staticPath,darkThemeRoute,routeNewBarTim,kqdPath,outsidePage } from "@/utils/consts";
import { G60site,TanSite,AISite } from "@/router/index";
// import { userPermission } from '@/api/user'
import TIM,{ tim } from '@/config/WebTIMConfig';
import { routerWindow,LaTrack,aTrack } from '../utils/tool.js'; // openVipTipWindow
// import { checkRealApi } from "@/api/live.js";
import { timeFromList } from "@/utils/time";
import { memberDetails,getUserVerifyStatus } from "@/api/user"; // checkUserDiction
import { ElMessage } from "element-plus";
import { getEnterpriseInfo } from "@/api/organization";
import { checkInfoComplete } from "@/api/user";
import realMember from "@/components/RealMember";
import { judgeNavbar } from "@/utils/auth.js"
import { getHotSearch } from "@/api/search";
import {environment} from "@/utils/consts";
import { setCookie } from "@/utils/Cookies/index";
import { tanVipJudge } from "@/utils/auth";

export default {
  name: "Navbar",
  computed: {
    isOutside(){
      return outsidePage.includes(this.routePath)
    },
    unReadMessages(){
      // 所有未读消息
      return this.systemMessageNum + this.messageNum
    },
    hasBottomLine(){
      // 是否显示底线
      return !(['/information','/industry','/capital','/profundity','/technology'].includes(this.$route.path) || this.$route.path.indexOf('-third') !== -1)
    },
    notLive(){
      // 非路演页面
      return !['/live','/live-room'].includes(this.$route.path)
    },
    hasTim(){
      //是否显示消息
      return routeNewBarTim.includes(this.$route.path)
    },
    ...mapGetters([
      "smallScreen",
      "openCreateLive",
      "messagePending", // 消息加载
      "reloadUser",
      "visibility",
      "navbarBread",
      "showDark",
      "enterpriseType",
      "showMiddle",
      "showTop",
      "token",
      "name",
      "avatar",
      "id",
      "changeBgByScroll",
      "organizationId",
      "level",
      "vipEndTime",
      "addressLocation",
      "imWindowVisible",
      "systemMessageNum",
      "updateNavbar",
    ]),
    routePath(){
      // 路由位置
      return this.$route.path
    },
    routeQuery(){
      return this.$route.query
    },
    // useBlackLogo() {
    //   return this.navbarAtTop && this.changeBgByScroll
    // }
  },
  beforeUnmount(){  // 移除事件监听
    window.removeEventListener("scroll", this.handleScroll)
    window.onscroll = () => {}
    // 关闭时销毁定时器
    if(this.unreadMessages) clearInterval(this.unreadMessages)
  },
  components: {
    CreateLiveDialog,
    realMember,
  },
  data() {
    return {
      environment,
      scrollLeft: 0, // 横向滚动距离
      kqdPath,  // kqd前缀
      reverseAngel: 0,  // 旋转角度
      showInterval: null, // 旋转时间
      hideInterval: null,  // 旋转事件
      showPublished: false, // 是否展示发布
      isCustom: false,  // 是否定制会员
      isNewVip: false , // 是否新会员
      hotSearch: '',  // 热门搜索
      vipInfo: {},  // vip状态
      loadingAnnounce: false, // 加载宣传中
      darkThemeRoute, // 暗色路由
      scrollHeight: 0, // 滚动距离
      chooseType: 1, // 搜索选择
      inputWord: '',  // 搜索关键词
      cur: 1, //颜色
      curs: 1, //导航条
      unreadMessages: null,// 定时器
      messageNum: 0,
      vipLoading: false, // VIP加载
      showCreatliveDialog: false, // 创建路演窗口
      isShowDialog: false, // 实名弹层
      isLogin: false,
      staticPath,
      normalUser: false, // 判断用户是否无组织
      userName: "", // 用户名称
      userAvatar: "", // 用户头像
      noOrgVIP: false, // 无组织的VIP
      loadingInfo: false,
      hasOrganization: false,
      // navbarAtTop: true,
      levelFlag: false,
      organizationVipType: -1, // vip类型
      organizationVipEndTime: "",
      viptime: "",
      vipStartTime: "",
      isLab: false,
      showEditButton: false,
      isOpacity: false,
      opacityStyle: {
        backgroundColor: `rgba(255,255,255,1)`,
      },
    };
  },
  async mounted() {
    this.initPage()
  },
  async activated() {
    setTimeout(() => {
      this.getLevel();
    }, 500);
    this.messageNum = Number(localStorage.getItem("messageCounter"));
    let userInfo = localStorage.getItem("userInfo");
    if (!userInfo) return;
    userInfo = JSON.parse(userInfo);
    await this.refreshInfo(userInfo);
  },
  watch: {
    openCreateLive(){
      // 打开窗口
      this.openCreateLiveWindow()
    },
    reloadUser(){
      // 刷新头像
      this.initPage()
    },
    visibility(val){
      // 窗口变动事件
      if (val) {
        //切换到该页面时执行
        if(!this.isLogin && localStorage.getItem("userInfo")){
          // 说明在别的页面登录
          setTimeout(()=>{
            location.reload()
          },1000)
        }
        else if(this.isLogin && !localStorage.getItem("userInfo")){
          // 说明在别的页面登录
          ElMessage.warning('检测到退出登录，刷新页面!')
          setTimeout(()=>{
            location.reload()
          },1000)
        }
      }
    },
    async token() {
      this.updateLoginStatus();
      if (getToken() != "") this.getLevel();
    },
    name() {
      this.userName = this.name;
      return this.userName;
    },
    messageCounter() {
      // 解决vuex刷新数据清空问题
      this.messageNum = Number(localStorage.getItem("messageCounter"));
    },
    closed() {
      if (this.closed == false && this.isLogin) {
        this.initIMConnection();
      }
    },
    updateNavbar(){
      // 切换更新导航栏
      this.initPage()
    },
    async $route(to, from) {
      if (from == "/login") {
        this.getLevel();
      } else if (to == "/my-order") {
        this.getLevel();
      }
      if(to.path === '/selection'){ // 如果路由变化，修改对应的cur
        this.setCur()
      }
      this.initPage()
    },
  },
  methods: {
    aTrack,
    backToHome(){
      this.$router.push('/')
    },
    openSearch(){
      // 打开搜索页
      routerWindow(this.$router,"/search-center",{
        keyword: '',
        city:  '',
        school:  '',
        college: '',
        title: '',
        offset: 1,
        searchType: 1,
        analyzerType: 2,
        searchName : 0,
        type: '0'
      })
    },
    myOrder(){
      // 我的订单页面
      routerWindow(this.$router,'/my-order',{})
    },
    showPublish(){
      // 发布按钮显示
      if(this.hideInterval){
        clearInterval(this.hideInterval)
        this.hideInterval = null
        this.showPublished = true
        this.reverseAngel = -180
      }
      else if(this.showInterval){
        clearInterval(this.showInterval)
        this.showInterval = null
        this.showPublished = true
        this.reverseAngel = -180
      }
      else{
        let that = this
        this.showInterval = setInterval(()=>{
          that.reverseAngel -= 10
          if(that.reverseAngel <= -180){
            that.reverseAngel = -180
            that.showPublished = true
            clearInterval(that.showInterval)
            that.showInterval = null
          }
        },10)
      }
    },
    hidePublish(){
      // 隐藏发布按钮
      if(this.showInterval){
        clearInterval(this.showInterval)
        this.showInterval = null
        this.showPublished = false
        this.reverseAngel = 0
      }
      else if(this.hideInterval){
        clearInterval(this.hideInterval)
        this.hideInterval = null
        this.showPublished = false
        this.reverseAngel = 0
      }
      else{
        let that = this
        this.hideInterval = setInterval(()=>{
          that.reverseAngel += 10
          if(that.reverseAngel >= 0){
            that.reverseAngel = 0
            that.showPublished = false
            clearInterval(that.hideInterval)
            that.hideInterval = null
          }
        },10)
      }
    },
    breadJump(path){
      // 面包屑跳转
      if(!path) return
      else if(path.indexOf('?') !== -1) {
        // 说明有参数
        window.open(kqdPath + path, "_blank");
      }
      else{
        routerWindow(this.$router,path)
      }
    },
    async newEdit(type){
      // 打开新的编辑窗口 type 0 数字科创 1 个人
      let userInfo = localStorage.getItem("userInfo");
      let res = {}
      switch (type) {
        case 0:
          // 先判断是否创建
          userInfo = JSON.parse(userInfo);
          res = await memberDetails(userInfo.id);
          if (
            !userInfo.organizationId ||
            (res.data.organizationName.startsWith(res.data.phone) && res.data.organizationName.length === (res.data.phone + '').length)
          ) {
            // 包括普通用户
            // this.$store.dispatch("popup/setPopup", {
            //   type: 11, // 修改数字科创
            //   parameter: {},
            // });
            tanVipJudge({
              pRes: res,
            })
            return;
          }
          this.$store.dispatch("popup/setPopup", {
            type: 13, // 修改数字科创
            parameter: {},
          });
          break;
        default:
          this.$store.dispatch("popup/setPopup", {
            type: 12, // 修改个人信息
            parameter: {},
          });
          break;
      }
    },
    newPublish(path){
      // 新的发布按钮
      switch (path) {
        case '/postdynamic':
          LaTrack('e_post_dynamic_from_tabbar')
          break;
        case '/postdemand':
          LaTrack('e_post_demand_from_tabbar')
          break;
        case '/postTech':
          LaTrack('e_post_project_from_tabbar')
          break;
        case '/huiyi':
          LaTrack('e_post_conference_from_tabbar')
          break;
        default:
          break;
      }
      routerWindow(this.$router,path)
    },
    showIMWindow() {
      // 开关聊天窗口
      if (!this.isLogin) {
        ElMessage.warning("您尚未登录!");
        this.jumpTo("/login");
        return;
      }
      this.messageNum = Number(localStorage.getItem("messageCounter"));
      if (this.imWindowVisible) {
        this.$store.dispatch("message/hideIMWindow");
        return;
      }
      const _this = this
      const userInfo = JSON.parse(localStorage.getItem('userInfos'))
      const promise = tim.getUserStatus({userIDList: [`${userInfo.memberInfo.id}`]});
      promise.then(function(imResponse) {
        const { successUserList } = imResponse.data;
        successUserList.forEach((item) => {
          const { statusType } = item;
          // userID - 用户 ID
          // statusType - 用户状态，枚举值及说明如下：
          // TIM.TYPES.USER_STATUS_UNKNOWN - 未知
          // TIM.TYPES.USER_STATUS_ONLINE - 在线
          // TIM.TYPES.USER_STATUS_OFFLINE - 离线
          // TIM.TYPES.USER_STATUS_UNLOGINED - 未登录
          if(statusType === TIM.TYPES.USER_STATUS_UNLOGINED || statusType === TIM.TYPES.USER_STATUS_OFFLINE){
            // 未登录状态
            _this.$store.commit('user/SET_TIM_CHANGE');
          }
        });
      }).catch(function(imError) {
        console.warn('获取用户信息失败:', imError); // 获取用户状态失败的相关信息
      }).finally(()=>{
        this.$store.dispatch("message/showIMWindow");
      })
    },
    async getHot(){
      let res = await getHotSearch()
      this.hotSearch = res.data.values.join(' ')
    },
    async initPage(){
      // 初始化页面
      if (this.$route.path == "/") {
        //首页有视频，需要设置透明色
        this.isOpacity = true;
        this.opacityStyle = {
          backgroundColor: `rgba(255,255,255,0)`,
        };
        // this.getHot()
      }
      // if(this.hotSearch.length === 0){
      //   this.getHot()
      // }
      window.addEventListener("scroll", this.handleScroll); // 监听是否展示完整navbar
      this.$nextTick(() => {
        if (
          location.href.split("#")[0].indexOf("/?openLive=true") !== -1 &&
          location.href.split("#")[0].indexOf("redirect") === -1
        ) {
          this.openCreateLiveWindow(); // 打开路演窗口
        }
      });
      this.updateLoginStatus();
      this.userName = this.name;
      this.userAvatar = this.avatar;
      this.unreadMessages = setInterval(this.getUnreadMessage, 100); //设置刷新未读消息
      this.setCur()  // 获取当前页面的cur
      this.setType()  // 获取当前页面的chooseType
      this.setCurs()  // 获取当前页面的curs
      this.setScroll()  // 设置横向滚动
      let scrollTop = document.documentElement.scrollTop;
      judgeNavbar(this.scrollHeight-scrollTop,scrollTop,this.$route)
      this.scrollHeight = scrollTop
    },
    getUnreadMessage() {
      // 获取未读消息
      this.messageNum = Number(localStorage.getItem("messageCounter"));
    },
    workSpaceTitle(){ // 工作中心标题
      // if(localStorage.getItem('userInfo')){
      //   const userInfo = JSON.parse(localStorage.getItem('userInfos'))
      //   switch (userInfo.memberInfo.organizationType) {
      //     case 0:
      //       return '我的数字科创中心'
      //     case '0':
      //       return '我的数字科创中心'
      //     case 1:
      //       return '我的数字科创中心'
      //     case '1': // this.enterpriseType
      //       return '我的数字科创中心'
      //     default:
      //       return '工作中心'
      //   }
      // }
      // else{
      //   return '工作中心'
      // }
      return '我的工作中心' // 2.2.7 统一为我的工作中心
    },
    setScroll(){  // 设置横向滚动
      this.scrollLeft = Math.max(
        document.body.scrollLeft,
        document.documentElement.scrollLeft
      );
      window.onscroll = () => {
        this.scrollLeft = Math.max(
          document.body.scrollLeft,
          document.documentElement.scrollLeft
        );
      };
    },
    setCur(){ // 根据当前页面修改cur
      let path = this.$route.path
      let type = this.$route.query.type
      switch (path) {
        case '/selection':
          if(!type) this.cur = 1
          else if(type == 1) this.cur = 2
          else if(type == 2) this.cur = 3
          break;
        default:
          this.cur = 1
          break
      }
    },
    setType(){  // 根据当前页面修改chooseType
      let path = this.$route.path
      switch (path) {
        case '/search':
          this.chooseType = 1 // 默认是找专家
          if(this.$route.query.searchType == 8) this.chooseType = 4 // 找技术成果
          break;
        case '/enterpriseDynamic':
          this.chooseType = 3
          break;
        case '/newsDetail': // 新闻详情
          this.chooseType = 3
          break;
        case '/searchdetail': // 技术详情
          this.chooseType = 2
          break
        case '/searchDynamic':
          this.chooseType = 2
          break
        default:
          this.chooseType = 1
          break;
      }
    },
    setCurs(){  // 根据当前页面修改curs
      let path = this.$route.path
      let fromSearch = this.$route.query.fromSearch
      switch (path) {
        case '/':
          this.curs = 1
          break;
        case '/enterpriseDynamic':
          this.curs = 2
          break;
        case '/newsDetail':
          this.curs = 2
          break;
        case '/searchDynamic':
          this.curs = 3
          break;
        case '/searchdetail':
          this.curs = 3
          break;
        case '/search':
          this.curs = 4
          break;
        case '/my-lab':
          this.curs = 4
          break;
        case '/my-lab-doctor':
          this.curs = 4
          break;
        case '/live-room':
          this.curs = 5
          break;
        case '/live':
          this.curs = 5
          break;
        case '/live-list':
          this.curs = 5
          break;
        case '/vip':
          this.curs = 7
          break;
        case '/enterpriseUser':
          this.curs = 9
          break;
        case '/spread':
          this.curs = 8
          break;
        case '/search-demand':
          this.curs = 10
          break;
        case '/demand-detail':  // 需求详情
          if(fromSearch && fromSearch == 1) this.curs = 10  // 搜索说明是需求库进入
          else this.curs = -1
          break;
        default:
          if(['/industrial-garden','/government','/company'].includes(path)){
            this.curs = 6
          }
          else{
            this.curs = -1
          }
          break;
      }
    },
    setLocalUserInfo(res){
      // 获取用户信息及对缓存的修改
      // 设置VIP身份
      let vip = JSON.parse(localStorage.getItem('userInfo'))
      vip.isVip = res.data.isVip
      localStorage.setItem("userInfo", JSON.stringify(vip))
      // 设置企业类型等
      let userInfo = JSON.parse(localStorage.getItem('userInfos'))
      userInfo.memberInfo.organizationId = res.data.organizationId
      userInfo.memberInfo.organizationType = res.data.organizationType
      localStorage.setItem("userInfos", JSON.stringify(userInfo))
      this.$store.dispatch("user/editEnterpriseId", res.data.organizationId);
      if(userInfo.memberInfo.organizationType !== 1) return
      // let type = await memberDetails(userInfo.memberInfo.id)
      if(!res) return
      this.$store.commit("user/SET_ORGANIZATION",{id: res.data.organizationId,name: res.data.organizationName,type:res.data.organizationType})
      this.$store.commit("user/SET_ENTERPRISE_TYPE",res.data.enterpriseTypeName ? res.data.enterpriseTypeName : '数字科创中心')
    },
    clickColor(type) {
      if(!this.isLogin){
        ElMessage.warning('您尚未登录!')
        this.$router.push('/login')
        return
      }
      this.cur = type;
      switch (type) {
        case 1:
          // TODO_Track: 进入路演活动 @click="aTrack('e_tabbar_mine')"
          this.$router.push({
            path: '/c-center'
          })
          break;
        default:
          break;
      }
    },
    openSpread(type) {
      // 打开推广页
      if (type === 1) {
        this.$router.push("/enterpriseUser");
        return;
      }
      this.$router.push("/spread");
    },
    handleScroll() {
      var scrollTop = document.documentElement.scrollTop;
      if (scrollTop < 5) {
        this.isOpacity = true;
        this.opacityStyle = {
          backgroundColor: `rgba(255,255,255,0)`,
        };
      }
      if (scrollTop > 100) {
        let opacity = scrollTop / 140;
        opacity = opacity > 1 ? 1 : opacity; //渐变色从0到1
        this.opacityStyle = {
          backgroundColor: `rgba(255,255,255,${opacity})`,
        };
        this.isOpacity = false;
      }
      judgeNavbar(this.scrollHeight-scrollTop,scrollTop,this.$route)  // 1.10.11 滚动隐藏
      this.scrollHeight = scrollTop
    },
    commitSearch() {
      if(this.inputWord.length === 0){  // 空判断
        ElMessage.warning('请输入搜索关键词!')
        return
      }
      switch (this.chooseType) {
        case 1:
          routerWindow(this.$router,"/search-center",{
            keyword: this.inputWord,
            city:  '',
            school:  '',
            college: '',
            title: '',
            offset: 1,
            searchType: 1,
            analyzerType: 2,
            searchName : 0,
            type: '0'
          })
          break;
        case 2:
          routerWindow(this.$router,"/search-center",{
            keyword: this.inputWord,
            page: 1,
            techId: '',
            industryId: '',
            corId: '',
            typeId: '',
            type: '1'
          })
          break;
        case 3:
          routerWindow(this.$router,"/search-center",{
            keywords: this.inputWord,
            page: 1,
            channelId: '',
            industryId: '',
            type: '3'
          })
          break;
        case 4:
          routerWindow(this.$router,"/search-center",{
            keyword: this.inputWord,
            city:  '',
            school:  '',
            college: '',
            title: '',
            offset: 1,
            searchType: 8,
            analyzerType: 2,
            searchName : 0,
            type: '2'
          })
          break;
        default:
          break;
      }
    },
    checkVipPage() {
      if (checkVip(0)) {
        return;
      }
      this.jumpTo("/vip");
    },
    customerService() {
      // 联系客服
      this.$store.commit("message/SET_OPEN_SERVICE_WINDOW");
      // 客服ID uat: 185580801622017 线上: 180517873975297
      // let id = (environment === 'tprod' || environment === 'online' ) ? 180517873975297 : 185580801622017
      // this.$store.dispatch("message/startCommunication", {id: id,serverId:'',title:'',quickChat:false, unlimited: true})  // 在线联系不需要需求id、标题及承接需求quickChat
    },
    openCreateLiveWindow() {
      LaTrack('e_post_live_from_tabbar')
      if (!this.isLogin) {
        ElMessage.warning("您尚未登录!");
        this.$router.push({
          path: "/login",
          query: { redirect: "/", openLive: true },
        });
        return;
      }
      routerWindow(this.$router,'/roadshow',{})
      // let member = JSON.parse(localStorage.getItem("userInfo"));
      // checkRealApi(member.id).then(async (res) => {
      //   if (res.data == true) {
      //     let permission = await checkUserDiction({uniqueLab:'/live/forward'})
      //     if(!permission.data){
      //       // 无权限
      //       openVipTipWindow()
      //       return
      //     }
      //     this.showCreatliveDialog = true;
      //   } else {
      //     this.isShowDialog = true;
      //   }
      // });
    },
    closeWindow() {
      // 关闭显示
      this.isShowDialog = false;
    },
    isShow(val) {
      if (val.data == true) {
        ElMessage.info("实名认证成功");
        this.isShowDialog = false;
      }
    },
    closeCreateLiveWindow() {
      // 关掉创建路演表单
      this.showCreatliveDialog = false;
    },
    timeFromList,
    async setOrgInfo(res){
      if(localStorage.getItem('userInfos')){ // 获取企业类型
        let userInfo = JSON.parse(localStorage.getItem('userInfos'))
        let res1 = res
        if(userInfo.memberInfo.organizationType && !userInfo.memberInfo?.organizationId){ // 有类型无ID
          this.$store.dispatch("user/editEnterpriseId", res1.data.organizationId);
          userInfo = JSON.parse(localStorage.getItem('userInfos'))  // 保存ID后获取
        }
        this.vipInfo.isOldVip = res1.data.isVip
        if(res1.data.organizationId){
          // 有组织
          let verify = await getUserVerifyStatus({id:userInfo.memberInfo.id})
          this.vipInfo.status = verify.data.verifyStatus
          if(res1.data.organizationType + '' === '1'){
            // 企业
            this.vipInfo.isVip = res1.data.organizationVipType + '' === '4'
            this.vipInfo.vipType = res1.data.organizationVipType + '' === '5'
          }
          else{
            this.vipInfo.isVip = res1.data.vipType + '' === '4'
            this.vipInfo.vipType = res1.data.vipType + '' === '5'
          }
        }
        else{
          this.vipInfo.isVip = false
          this.vipInfo.vipType = false
          this.vipInfo.status = -1
        }
        if(userInfo.memberInfo.organizationType !== 1) return
        this.$store.commit("user/SET_ENTERPRISE_TYPE",res1.data.enterpriseTypeName ? res1.data.enterpriseTypeName : '数字科创中心')
      }
    },
    async updateLoginStatus() {
      if(G60site || TanSite || AISite) return
      this.isLogin = getToken() !== "";
      this.getLevel();
    },
    getLevel() {
      let userInfo = localStorage.getItem("userInfo");
      if (!userInfo) return; // 说明未登录
      let { organizationType, id } = JSON.parse(userInfo);
      this.levelFlag = checkVip(0);
      this.vipLoading = true;
      if (this.levelFlag) {
        // 是会员
        memberDetails(id)
          .then((res) => {
            // 从接口中获取
            if (!res) {
              // 可能是token过期
              setTimeout(() => {
                location.reload(); // 重新加载
              }, 3000);
              return;
            }
            this.setOrgInfo(res)
            this.levelFlag = res.data.isVip
            this.organizationVipType = res.data.organizationVipType;
            this.$store.commit("user/SET_NAME", res.data.name);
            if (organizationType === 1) {
              this.isCustom = this.organizationVipType  + '' === '5'
              this.isNewVip = this.organizationVipType  + '' === '4'
              this.viptime = this.timeFromList(
                res.data.organizationVipEndTime?.splice(0, 3)
              );
              this.vipStartTime = this.timeFromList(
                res.data.organizationVipStartTime.slice(0, 3)
              );
            } else {
              this.isCustom = res.data.vipType  + '' === '5'
              this.isNewVip = res.data.vipType  + '' === '4'
              this.viptime = this.timeFromList(
                res.data.vipEndTime?.slice(0, 3)
              );
              this.vipStartTime = this.timeFromList(
                res.data.vipStartTime.slice(0, 3)
              );
            }
            this.setLocalUserInfo(res)
          })
          .finally(() => {
            this.vipLoading = false;
          });
      } else {
        memberDetails(id)
          .then((res) => {
            if (!res) {
              // 可能是token过期
              setTimeout(() => {
                location.reload(); // 重新加载
              }, 3000);
              return;
            }
            this.setOrgInfo(res)
            this.$store.commit("user/SET_NAME", res.data.name);
            if (res.data.isVip) {
              this.levelFlag = true;
              this.organizationVipType = res.data.organizationVipType;
              if (res.data.organizationType === 1) {
                this.isCustom = res.data.vipType  + '' === '5'
                this.isNewVip = res.data.vipType  + '' === '4'
                this.viptime = this.timeFromList(
                  res.data.organizationVipEndTime?.splice(0, 3)
                );
                this.vipStartTime = this.timeFromList(
                  res.data.organizationVipStartTime.slice(0, 3)
                );
              } else {
                this.isCustom = res.data.vipType  + '' === '5'
                this.isNewVip = res.data.vipType  + '' === '4'
                this.viptime = this.timeFromList(
                  res.data.vipEndTime?.slice(0, 3)
                );
                this.vipStartTime = this.timeFromList(
                  res.data.vipStartTime.slice(0, 3)
                );
              }
            }
            this.setLocalUserInfo(res)
          })
          .finally(() => {
            this.vipLoading = false;
          });
      }
    },
    async refreshInfo(userInfo) {
      this.loadingInfo = true;
      // 获取用户信息，修改名字和头像
      let currentUser = await memberDetails(userInfo.id);
      if (currentUser.data.name != this.userName) {
        this.$store.dispatch("user/editUserName", currentUser.data.name);
        userInfo.name = currentUser.data.name;
        this.userName = currentUser.data.name;
      }
      if (currentUser.data.headImage != this.userAvatar) {
        this.$store.dispatch("user/changeAvatar", currentUser.data.headImage);
        userInfo.avatar = currentUser.data.headImage;
        this.userAvatar = currentUser.data.headImage;
      }
      userInfo.isVip = currentUser.data.isVip;
      localStorage.setItem("userInfo", JSON.stringify(userInfo)); // 更改后存入缓存
      this.$store.commit("user/SET_NAME", currentUser.data.name);
      // 延时判断loading,因为无组织用户不会进行getNavSelect()结束载入
      let that = this;
      setTimeout(() => {
        if (that.loadingInfo) {
          that.loadingInfo = false;
        }
      }, 2000);
    },
    async getNavSelect() {
      let userInfos = localStorage.getItem("userInfos");
      userInfos = JSON.parse(userInfos);
      let userInfo = localStorage.getItem("userInfo");
      userInfo = JSON.parse(userInfo);
      if (!userInfo?.organizationType && userInfo?.organizationType !== 0)
        this.normalUser = true; // 判断是否无组织 用类型进行判断 实验室0 企业1
      // this.setupScrollActions() // 未登录也需要对背景色进行调整
      this.staticPath = staticPath;
      if (!userInfos) {
        // 无组织用户此处可能会跳出
        return;
      }
      if (userInfo) {
        if (userInfo.organizationType === 0) {
          this.isLab = true;
        } else {
          this.isLab = false;
        }
      }
      // 解决f5刷新之后未读消息消失问题
      this.messageNum = Number(localStorage.getItem("messageCounter"));
      if (this.id !== -1) {
        this.$store.dispatch("user/updateVipInfo");
      }
      this.updateLoginStatus();
      if (userInfo.organizationType && !userInfo.organizationId) {
        // 新注册时，没有ID，但是有企业类型的情况
        let resOrg = await memberDetails(userInfo.id);
        userInfo.organizationId = resOrg.data.organizationId;
        this.$store.commit("user/SET_NAME", resOrg.data.name);
        localStorage.setItem("userInfo", JSON.stringify(userInfo)); // 读取之后存入缓存
      }
      if (userInfo && userInfo.organizationId) {
        try {
          await this.refreshInfo(userInfo);
          let res2 = await getEnterpriseInfo(userInfo.organizationId); //  获取企业信息
          if (!res2.data.organizationDto.addressAreaId) {
            // 说明是无组织的VIP
            this.noOrgVIP = true;
          }
          let memberId = res2.data.memberResList.find((item) => {
            if (item.role === 2) {
              // 找到管理
              return item;
            }
          });
          if (memberId && userInfo.id == memberId.id) {
            // 说明是企业负责人
            this.showEditButton = true;
          }
        } catch (error) {
          this.loadingInfo = false;
        }
      }
      if (userInfo.organizationId == null) {
        this.hasOrganization = false;
      } else {
        this.hasOrganization = true;
      }
      this.loadingInfo = false;
    },
    getInfoFlag() {
      // 先进行登录状态判断
      let userInfo = localStorage.getItem("userInfo");
      if (!userInfo) {
        ElMessage.warning("你尚未登录!");
        this.jumpTo("/login");
        return;
      }
      // let route = {fullPath: '/postdynamic'}
      // userPermission(JSON.parse(userInfo).id,route).then(
      //   checkInfoComplete().then(res => {
      //     if (res.data && res.data.flag) { //flag-true,需要完善个人信息
      //       this.$router.push({
      //         path: '/postdynamic',
      //         query: {
      //           info: JSON.stringify(res.data)
      //         }
      //       })
      //     } else {
      //       this.jumpTo('/postdynamic')
      //     }
      //   })
      // )
      checkInfoComplete().then((res) => {
        if (res.data && res.data.flag) {
          //flag-true,需要完善个人信息
          this.$router.push({
            path: "/postdynamic",
            query: {
              info: JSON.stringify(res.data),
            },
          });
        } else {
          this.jumpTo("/postdynamic");
        }
      });
    },
    setupScrollActions() {
      if (this.changeBgByScroll) {
        this.dynamicNavbarStyle();
      } else {
        window.onscroll = () => {
          let sl = -Math.max(
            document.body.scrollLeft,
            document.documentElement.scrollLeft
          );
          if (this.$refs.navbarWrapper != null) {
            this.$refs.navbarWrapper.style.left = sl + "px";
          }
        };
        let colorStyle = document.querySelector(".navbar-color");
        colorStyle.style.setProperty("--bgColor", "rgba(255, 255, 255, 255)");
        colorStyle.style.setProperty("--shadowColor", `rgba(0, 0, 0, 0.06)`);
        colorStyle.style.setProperty("--fontColor", "#333333");
      }
    },
    // dynamicNavbarStyle() {
    //   let colorStyle = document.querySelector(".navbar-color");
    //   let threshold = 500;
    //   let dynamicStyleFunction = () => {
    //     let scrollTop = document.documentElement.scrollTop
    //     colorStyle.style.setProperty("--bgColor", `rgba(255, 255, 255, ${scrollTop > threshold ? 1 : scrollTop / threshold})`)
    //     colorStyle.style.setProperty("--fontColor", scrollTop > (threshold * 0.75) ? '#333333' : '#FFFFFF')
    //     this.navbarAtTop = !(scrollTop > (threshold * 0.75))

    //     let sl = -Math.max(document.body.scrollLeft, document.documentElement.scrollLeft);
    //     this.$refs.navbarWrapper.style.left = sl + 'px';
    //   }
    //   dynamicStyleFunction()
    //   window.onscroll = dynamicStyleFunction
    // },
    async jumpTo(path) {
      if (path === "/my-lab") {
        let userInfo = localStorage.getItem("userInfo");
        userInfo = JSON.parse(userInfo);
        let res = await memberDetails(userInfo.id);
        if (
          !userInfo.organizationId ||
          (res.data.organizationName.startsWith(res.data.phone) && res.data.organizationName.length === (res.data.phone + '').length)
        ) {
          // 包括普通用户
          ElMessage.warning("您尚未创建空间!");
          return;
        }
        if (userInfo.organizationId && userInfo.organizationType === 1) {
          this.$router.push({
            path: "/lab",
            query: {
              id: userInfo.organizationId,
              identity: userInfo.organizationType,
            },
          });
          return;
        }
      }
      // 关闭聊天对话框
      this.$store.dispatch("message/hideIMWindow");
      this.$router.push(path);
    },
    // 聊天企业改版上线的时候，要屏蔽隐藏
    gotoLive() {
      let query = { roomId: 123456 };
      this.$router.push({ path: "/live-room", query: query });
    },
    async commitLogout() {
      localStorage.removeItem("postData"); // 退出时，清除草稿记录
      localStorage.removeItem("newMediasCover");
      localStorage.removeItem("newMediasData");
      localStorage.removeItem("finalDraft"); // 退出时，清除需求的草稿记录
      localStorage.removeItem("DynamicDraft"); // 退出时，清除动态的草稿记录
      localStorage.removeItem("LocalVersion"); // 退出时，清除版本号
      localStorage.removeItem("TechniqueDraft"); // 退出时，清除成果的草稿记录
      localStorage.removeItem("showBread"); // 退出时，清除showBread
      localStorage.removeItem("loginTime"); // 退出时，清除登录时间戳
      localStorage.removeItem("localLeaveMessage")  // 退出时，清除未读留言个数
      localStorage.clear();
      setCookie('KqdInfo',{status: '0'})
      // localStorage.removeItem('updateCom')      // 退出时，清楚企业更新标记
      this.$store.commit("user/SET_ENTERPRISE_TYPE",'') // 清空类型
      this.$store.commit("message/SET_CLOSE_SERVICE_WINDOW");
      this.$store.dispatch("popup/setPopup", {
        type: -1, // 关闭弹窗
        parameter: {},
      });
      this.$store
        .dispatch("user/logout")
        .then(() => {
          if (this.$route.path === "/") {
            // 返回到最初的首页
            this.$router.push({
              path: "/",
              query: {
                backToIndex: 1,
              },
            });
          } else this.$router.push("/");
        })
        .then(() => {
          // location.reload()
        });
      this.$store.dispatch("user/logoutClosed");
    },
  },
};
</script>

<style scoped lang="scss">

.publish-button-list{
  p{
    width: 100%;
    line-height: 36px;
    height: 36px;
    text-align: center;
    cursor: pointer;
  }
  p:hover{
    background: #2282ff14;
  }
}
.small-screen{
  .new-text{
    padding: 0 10px !important;
  }
  .header{
    min-width: 1200px !important;
  }
  .nav{
    min-width: 1200px !important;

  }
  .nav-block{
    min-width: 1200px !important;

  }
  .navbar-align-wrapper{
    min-width: 1200px !important;
  }
  .new-line-width{
    width: 1200px !important;
  }
  .content{
    width: 1200px !important;
  }
  .router{
    width: 960px !important;
  }
}
.header {
  height: 96px;
  width: 100%;
  min-width: 1400px;
  background: #F8F8F8;
  position: fixed;
  top: 0;
  z-index: 2001;
  .left{
    span:hover{
      cursor: pointer;
      color: $--color-theme;
    }
    .new-text{
      padding: 0 15px;
      line-height: 20px;
      height: 20px;
    }
    .active{
      color: $--color-theme;
      position: relative;
      &::after{
        content: '';
        width: 58px;
        height: 1px;
        background: #2280FF;
        position: absolute;
        bottom: -20px;
        left: 50%;
        margin-left: -29px;
      }
    }
    .new-down-button{
      margin: 0 20px;
      width: 60px;
      height: 19px;
      box-sizing: border-box;
      border-radius: 2px;
      border: 1px solid $--color-theme;
      color: $--color-theme;
      text-align: center;
      line-height: 17px;
    }
  }
  .content {
    height: 36px;
    font-size: 14px;
    display: flex;
    width: 1200px;
    margin: 0 auto;
    font-size: 400;
    line-height: 36px;
    color: #666;
    justify-content: space-between;
    align-items: center;
    .right {
      display: flex;
      align-items: center;
      .publish-button{
        position: relative;
        margin-left: 17px;
        .down-icon{
          margin-top: 2px;
          margin-left: 1px;
          width: 12px;
          height: 12px;
          background-image: url('../assets/images/navbar/down-icon.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }
      .publish-button-show{
        margin-right: 17px;
        position: relative;
        .up-icon{
          margin-top: 2px;
          margin-left: 1px;
          width: 12px;
          height: 12px;
          background-image: url('../assets/images/navbar/down-icon-hover.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }
      .middle{
        color: #666666;
        font-size: 12px;
        margin-right: 20px;
        width: 320px;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        height: 36px;
        p{
          max-width: 300px !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          white-space: nowrap !important;
          word-break: break-all !important;
        }
        img{
          width: 15px;
          height: 15px;
          margin-right: 7px;
        }
      }
      .colorBlue {
        color: #111111 ;
      }
      .colorBlue:hover{
        color: $--color-theme;
      }
      .left40 {
        margin-left: 6px;
        cursor: pointer;
        white-space: nowrap;
      }
      .login-button{
        width: 85px;
        height: 30px;
        background: $--color-theme;
        margin-left: 15px;
        text-align: center;
        line-height: 30px;
        color: #ffffff;
        border-radius: 4px;
      }
      .messages-container {
        display: flex;
        .item {
          overflow: hidden;
        }
        .system-message {
          cursor: pointer;
        }
        .over10 {
          line-height: 20px;
          width: 20px;
          height: 20px;
        }
        .over99 {
          line-height: 22px;
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}
.unread-message {
  position: absolute;
  right: 40px;
  top: -5px;
  color: white;
  font-size: 12px;
  background: #ce1908;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  width: 18px;
  height: 18px;
}
.no-top{
  top: 44px !important;
  height: 0px !important;
}
.no-middle{
  top: 44px !important;
  height: 0px !important;
}
.nav {
  height: 130px;
  width: 100%;
  min-width: 1400px;
  background: #fff;
  z-index: 2001;
  position: fixed;
  top: 44px;
  .content {
    width: 1200px;
    margin: 0 auto;
    background: #fff;
    .serch {
      margin-top: 20px;
      display: flex;
      margin-left: 680px;
      font-size: 16px;
      color: #666666;
      cursor: pointer;
      div {
        margin-left: 30px;
      }
      .color {
        color: $--color-theme;
      }
    }
    .logo {
      display: flex;
      justify-content: space-between;
      .logo-img {
        width: 150px;
        height: 55px;
        object-fit: cover;
      }
      .sou {
        display: flex;
        margin-top: 15px;
        margin-left: 73px;
        position: relative;
        .hot-search{
          position: absolute;
          bottom: 0;
          left: 0;
          max-width: 400px;
        }
        input {
          height: 44px;
          border: 1px solid $--color-theme;
          outline: none;
          width: 424px;
          border-radius: 4px 0px 0px 4px;
          padding-left: 14px;
          box-sizing: border-box;
          font-size: 16px;
          color: #333;
        }
        input::-webkit-input-placeholder {
          color: #8991a0;
          font-size: 14px;
        }
        .button {
          cursor: pointer;
          background: $--color-theme;
          width: 74px;
          height: 44px;
          border: none;
          outline: none;
          font-size: 14px;
          border-radius: 0px 4px 4px 0px;
          color: #fff;
          overflow: hidden;
          img{
            width: 16px;
            height: 16px;
          }
        }
      }
      .btn {
        width: 88px;
        height: 44px;
        background: $--color-theme;
        padding: 0;
        border-radius: 4px;
        border: none;
        color: #fff;
        margin-top: 15px;
        margin-left: 30px;
      }
    }
    .router {
      display: flex;
      width: 1200px;
      justify-content: space-between;
      div {
        width: 134px;
        height: 44px;
        font-size: 18px;
        color: #333333;
        background: #fff;
        text-align: center;
        line-height: 44px;
        cursor: pointer;
      }
      .color {
        background: $--color-theme;
        color: #fff;
      }
    }
  }
}

.convenient-btn {
  .convenient-usage {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f1f5f8;
    border-radius: 2px;
    cursor: pointer;
    img {
      width: 18px;
      height: 18px;
      margin-top: -3px;
      margin-right: 5px;
    }
    .text {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0f1c34;
    }
  }
  .margin-bottom {
    margin-bottom: 10px;
  }
}
.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.nav-block {
  height: 44px;
  width: 100%;
  min-width: 1400px;
}
.navbar-align-wrapper {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  min-width: 1400px;
  z-index: 1000;
}
.navbar-color {
  --bgColor: rgba(255, 255, 255, 0);
  --fontColor: #ffffff;
  --shadowColor: rgba(0, 0, 0, 0.06);
  box-shadow: 0 3px 3px var(--shadowColor);
  background: var(--bgColor);
  color: var(--fontColor);
}
.fix-top {
  position: fixed;
  top: 0;
}

.self-menu {
  font-size: 16px;
  color: #666666;
  display: flex;
  flex-direction: column;
  .user-info {
    display: flex;
    width: calc(100% + 2px);
    margin-left: -1px;
    margin-top: -1px;
    padding: 25px 0;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 4px 4px 0px 0px;
    background: $--color-theme;
    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .user-name {
      margin-top: 8px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
  .vip-services {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .vip {
      margin-top: 10px;
      width: 47%;
      height: 68px;
      padding: 5px 0;
      background: #f1f5f8;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0f1c34;
      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .top {
          font-size: 18px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
          .golden {
            width: 28px;
            height: 38px;
            margin-right: 5px;
          }
          .diamond {
            width: 38px;
            height: 38px;
            margin-right: 5px;
          }
          margin-right: 10px;
        }
      }
      .middle {
        display: flex;
        flex-direction: column;
        margin-left: 5px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8991a0;
      }
      .right {
        width: 46px;
        height: 32px;
        background: #feebd7;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #864d07;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 5px;
        margin-right: 10px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .full-width {
      width: 100%;
      .left {
        flex-direction: row !important;
        justify-content: flex-start;
        align-items: center;
      }
      .right {
        width: 56px;
        border-radius: 4px;
        margin-right: 10px;
        background: #dfe0e5;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8991a0;
      }
    }
  }
  .buttons {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 8px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(56,29,0,0.3);
    padding-bottom: 5px;
    .button:hover{
      background: #2282ff14;
      color: $--color-theme;
      .right-icon{
        background-image: url('../assets/images/navbar/right-icon-hover.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    .button {
      padding: 10px 20px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      .left-icon {
        margin-top: 2px;
        width: 16px;
        height: 16px;
        margin-right: 13px
      }
      .right-icon{
        background-image: url('../assets/images/navbar/right-icon.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      font-size: 16px;
      line-height: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0f1c34;
    }
    border-bottom: 2px solid #f1f5f8;
  }
  .exit-login {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 5px;
    .exit {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #8991a0;
      cursor: pointer;
    }
  }
}

.self-menu-item {
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.self-menu-item:hover {
  background: #ec8541;
  color: white;
}

.vip-info {
  font-size: 12px;
  color: #999999;
}

.vip-info span {
  margin-right: 5px;
}


.user-info-name {
  padding: 0 20px 0 10px;
  max-width: 140px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.user-info-name:hover {
  overflow: visible;
  white-space: inherit;
}
.has-bottom{
  // border-bottom: 2px solid $--color-theme;
}
.system-message {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .top-bar {
    display: flex;
    height: 40px;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border-bottom: 2px solid #f1f5f8;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #0f1c34;
    .left {
      span {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8991a0;
      }
      .unread-num {
        color: red;
      }
    }
    .right {
      font-size: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        margin-right: 5px;
      }
    }
  }
  .messages-wraper::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .messages-wraper {
    overflow: auto;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    max-height: 400px;
    width: 100%;
    .messages {
      padding: 18px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      border-bottom: 2px solid #f1f5f8;
      .top-info {
        display: flex;
        justify-content: space-between;
        .title {
          max-width: 340px;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #0f1c34;
        }
        .time {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #b0b5c0;
        }
      }
      .message {
        text-align: left;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8991a0;
        width: 100%;
        word-break: break-all;
      }
    }
  }
  .pagination-container {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 60px;
    align-items: center;
    border-bottom: 2px solid #f1f5f8;
  }
  .check-all {
    width: 100%;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #f57b15;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    cursor: pointer;
  }
}

.new-line{
  width: 100%;
  background-color: #FFFFFF;
  height: 60px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #F3F3F3;
  .logo{
    width: 99px;
    height: 33px;
    cursor: pointer;
    margin-right: 25px;
  }
  .search-button{
    .icon{
      width: 20px;
      height: 20px;
      margin-right: 1px;
      background-image: url('../assets/images/navbar/search-icon-new.png');
      background-size: 20px 20px;
      background-repeat: no-repeat;
    }
    &:hover{
      .icon{
        background-image: url('../assets/images/navbar/search-icon-new-hover.png') !important;
      }
      p{
        color: $--color-theme !important;
      }
    }
  }
}
.new-message-button:hover{
  .word{
    color: $--color-theme !important;
  }
}
.new-message-button{
  cursor: pointer;
  margin-left: 17px;
  .word{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #111111;
    line-height: 20px;
  }
  .show-message{
    background-image: url('../assets/images/navbar/message-icon-dark.png');
    background-size: 16px 18px !important;
    background-repeat: no-repeat !important;
    background-position: center center;
  }
  .un-show-message{
    background-image: url('../assets/images/navbar/message-icon-dark.png');
    background-size: 16px 18px !important;
    background-repeat: no-repeat !important;
    background-position: center center;
  }
}
</style>
