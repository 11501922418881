import {post} from "@/utils/request";
import store from '@/store'
import { searchPageSize } from "@/utils/consts"
import { TanSite } from "@/router/index";
import { getTanId } from "@/utils/Tan/login";


// 地图资源
export function searchMapInfo(){
  return post('/search/expertMap',{})
}

// 热门搜索
export function getHotSearch(){
    return post('/search/getHotSearch',{})
}

// 搜索统计
export function searchStatistics(params){
    const param = {
        "keyWord": params.keyword,
        "analyzerType" : 1, // params.analyzerType
        "searchType": params.types
      }
    return post('/search/searchStatistics',param)
}

// 搜索动态(找项目)
export function searchTechnology(params){
    let param = params.achievementType ? {
        achievementType: params.achievementType,
        cooperationType: params.cooperationType,
        industryIds: params.industryIds,
        keyword: params.keyword,
        limit: params.limit,
        memberId: store.getters.id,
        offset: params.offset,
        technologyMaturity: params.technologyMaturity,
        type: 4
    } : {
        cooperationType: params.cooperationType,
        industryIds: params.industryIds,
        keyword: params.keyword,
        limit: params.limit,
        memberId: store.getters.id,
        offset: params.offset,
        technologyMaturity: params.technologyMaturity,
        type: 4
    }
    if(params.areaId){
        // 八大领域
        param.areaId = params.areaId
    }
    return post('/search/searchTechnology',param)
}
// 获取推荐的动态
export function searchRecommendDynamics(params){
    let id = store.getters.id
    if(TanSite) id = getTanId()
    const param = {
        articlesType: "",
        cooperationType: "",
        industryId: "",
        orIndustryIds: params.ids,
        keyword: params.labels,
        limit: params.limit ? params.limit : 4,
        memberId: id,
        offset: params.offset,
        technologyMaturity: "",
        orderByCreateTime: true,    // 是否按时间排序
        type: 4
    }
    return post('/search/searchTechnology',param)
}


// 获取专家列表
export function labExpertList(searchForm,city,school,college,title,homePage=false){
    const param = {
        "city": city,
        "college": college,
        "id": homePage ? store.getters.id : searchForm.id,
        "limit": searchPageSize,
        "offset": searchForm.offset,
        "school": school,
        "title": title
    }
    return post('/lab/findExpertList',param)
}


// 搜索新闻(找新闻)
export function searchNews(industryId = '',keyword = '',limit = 10,memberId = '1',newsType = '',offset = 1,type = 9){
    const param = {
        industryId:industryId,
        keyword:keyword,
        limit:limit,
        memberId:memberId,  // 不能为空
        newsType:newsType,
        offset:offset,
        type:type
    }
    return post('/search/searchNews',param)
}

// 获取非VIP用户查询专家详情次数
export function viewExpertCount(id){
    return post('/lab/findNonVipViewExpertCountNew',{id:id})
}
// 检查是否可以查看专家详情
export function viewExpertCheck(id){
    return post('/lab/checkView',{id:id})
}

// 是否显示专家剩余次数
export function showViewCount(userId){
    const params = {
        "canalName": "pc",  // 访问端
        "memberId": userId,
        "pageParam": [],  // 页面参数
        "pagePath": '/pages/experience'
    }
    return post('/member/permission/check',params)
}


// 查询专家筛选条件(树形结构 支持游客) 2.2.0 以前
export function searchFilter(){
    return post('/search/screenConditionList')
}

// 查询专家筛选条件(树形结构 支持游客) 2.2.0 以后
export function searchFilterNew(){
    return post('/search/screenConditionListNew')
}

export function search (params) { // post Demo

    return post('/search/search', params)
}

// 在线专家列表
export function settledExperts(params){
    const param = {
        "college": '',
        "memberId": params.id,
        "limit": params.limit,
        "keyword": '',
        "offset":   params.offset,
        "school": '',
        "dbDataStatus": 1,   // 已入驻
        "type": 7
    }
    return post('/search/searchEpert', param)
}

// 专家名录搜索
export function expertDirectory(params){
    const param = {
        "college": params.college ? params.college : '',
        "id": params.memberId,
        "limit": params.limit,
        "offset": params.offset,
        "school": params.school,
        "dbDataStatus": 0,   // 导入的数据库专家
    }
    return post('/lab/findExpertListDirectories', param)
}

export function searchExpert (params,isLogin,city='',school='',college='',title='',searchByName=false){ // 找专家
    const param = {
        "analyzerType": 1,   // 颗粒度 params.analyzerType ? params.analyzerType : 2
        "city": '',
        "province": city,
        "college": college,
        "keyword": params.keyword,
        "limit": params.limit,
        "login": isLogin,
        "memberId": params.id,
        "offset": params.offset,
        "school": school,
        "title": title,
        "searchByName": searchByName,
        "type": 7
    }
    return post('/search/searchEpert', param)
}

export function searchExpertTemp (params,isLogin,city='',newParam=''){ // 找专家
    const param = {
        "analyzerType": 1,   // 颗粒度 params.analyzerType ? params.analyzerType : 2
        "city": newParam,
        "province": city,
        "college": '',
        "keyword": params.keyword,
        "limit": params.limit,
        "login": isLogin,
        "memberId": params.id,
        "offset": params.offset,
        "school": '',
        "title": '',
        "searchByName": false,
        "type": 7
    }
    return post('/search/searchEpert', param)
}
// 获取成果
export function searchAchievementsApi (params,isLogin,city='',school='',college='',title=''){ // 找技术成果
    const param = {
        "analyzerType": 1,   // 颗粒度 params.analyzerType
        "city": city,
        "college": college,
        "keyword": params.keyword,
        "limit": params.limit,
        "login": isLogin,
        "memberId": params.id,
        "offset": params.offset,
        "school": school,
        "title": title,
        "type": 8
    }
    return post('/search/searchAchievements', param)
}


export function expertDetails (params){ // 获取专家详情
    const param = {
        "id": params.id,
        "searchText": params.searchText,    // 成果词
        "memberId": params.memberId
    }
    return post('/lab/findExpertByIdNew',param)
}

export function searchCompany(params){  // 搜企业
    return post('/search/searchCompany',params)
}

export function searchPatent(params){   // 搜专利
    return post('/search/searchPatent',params)
}

export function searchDetailById(params){   // 根据id搜详情
    return post('/search/searchInfoById',params)
}

export function searchListIndustry(params){ // 搜产业
    return post('/search/searchListedCompany',params)
}

export function businessVipList(params){ // 获取商学院VIP列表
    return post('/specialVip/memberList',params)
}

export function areaAdmin(){
    return post('/admin/area')
}

export function associationSearch(params){
    return post('/search/association',params)
}