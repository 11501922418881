import router from './router'
import store from './store'
import NProgress from 'nprogress' // 页面顶部进度条
import 'nprogress/nprogress.css' // 进度条演示
import {getToken} from '@/utils/auth' // 从cookie中获取用户的token
// import getPageTitle from '@/utils/get-page-title'
import {ElMessage, ElMessageBox} from 'element-plus';
import {environment,sectionPaths,picPage,talentProjectSystemPage } from "@/utils/consts";
import { TPSPermission } from '@/utils/talent-project-system/router';
import { G60Permission } from '@/utils/G60/router';
import { TanPermission } from '@/utils/Tan/router';
import { AIPermission } from '@/utils/AI/router';
import { PLPermission } from '@/utils/post-line/router'
import { G60site,TanSite,AISite } from '@/router/index';
import { getCookie } from "@/utils/Cookies/index";

const em = ElMessage
NProgress.configure({showSpinner: false}) // NProgress 配置

const whiteList = ['/login', '/', "/yszc","/vipxy","/nrsm","/yhxy", "/live-room", "/postdemand", "/vip", "/contactus","/searchdetail", "/mobile-menu", "/search","/tempLogin", '/enterpriseSolution','/industrial-garden','/government','/spread', '/live-list', '/personal-center','/enterpriseUser','/company','/frointer','/newsDetail','/enterpriseDynamic','/searchDynamic','/search-demand','/capital-island','/search-center','/search-patent','/information','/technology','/industry','/capital','/profundity','/information-third','/technology-third','/industry-third','/project','/detail','/other-third','/home','/newLogin','/sections','/new-index','/not-found','/incubator','/incubator-vip','/search-industry','/show-json','/r-i','/SEI-register','/site-update-daily','/ability-workbench','/announce',...sectionPaths,...picPage,...talentProjectSystemPage] // 路由白名单，即不需要登录就能访问的路由
router.beforeEach(async (to, from, next) => {
    //恢复nav和footer的默认显示
    store.commit('message/SET_FOOTER',true)
    store.commit('message/SET_BG',true)

    if(top.location !== self.location){
        ElMessageBox.confirm('您访问的网站可能存在安全风险！请注意信息安全！', '警告', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'error'
        }).then(() => {
            em.error('请您谨慎访问！');
        }).catch(() => {
            em.error('请您谨慎访问！');
        });
    }

    // 进度条开始
    NProgress.start()
    // 设置路由页面的title
    document.title = to.meta.title
    if(to.meta.metaInfo && environment === 'online'){   // keywords, description 动态修改
        document.title = to.meta.title.replace('uwww','科企岛')
        document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.metaInfo.keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', to.meta.metaInfo.description.replace('uwww','科企岛'))
    }
    TPSPermission(to,from,next)
    if(G60site){
        G60Permission(to,from,next)
        next()
        return
    }
    else if(TanSite){
        TanPermission(to,from,next)
        next()
        return
    }
    else if(AISite){
        AIPermission(to,from,next)
        next()
        return
    }
    PLPermission(to,from,next)
    const hasCookie = getCookie('KqdInfo')

    // 确定用户是否已经登录
    const hasToken = getToken()

    if (hasToken) {
        if (to.path === '/login') {
            // 如果已经登录再访问/login，则定向至主页
            next({path: '/'})
            NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
        } else if (to.path === '/industry-setup') {
            if(!to.query.redirectFull && from.query.redirectFull){
                next({path: `/industry-setup`, query: {
                    redirectFull: JSON.stringify(from.query.redirectFull + (!from.query.redirectFull.includes('?') ? '?sDW=1' : '&sDW=1'))}
                })
            }
            else next()
        } else if (from.path === '/industry-setup' || (from.path === '/login' && to.path !== '/industry-setup')){
            // 选完行业 || 登录之后 判断路径
            if(from.query.redirectFull?.length > 0){
                window.location.href = JSON.parse(from.query.redirectFull)
                return
            }
            else{
                next({path: '/'})
            }
        } else {
            let userinfo = localStorage.getItem('userInfos')
            userinfo = JSON.parse(userinfo)
            let {memberIndustry,industryIds } = userinfo.memberInfo
            if ((memberIndustry != null && memberIndustry.length != 0) || industryIds?.length > 0) {
                if(to.path !== "/selection" && !userinfo.memberInfo.organizationId && from.path !== "/selection"){   // 无身份用户，不是去身份入驻页面
                    if(to.path === '/' && from.path === '/industry-setup'){
                        // 已经选完行业后的跳转
                        next()
                        return
                    }
                }
                next()
            } else {
                next({path: `/industry-setup`, query: {redirectFull: JSON.stringify(window.location.href + (!window.location.href.includes('?') ? '?sDW=1' : '&sDW=1'))}})
            }
        }
    } else {
        // 如果前往登录页面则进行保存
        const find = whiteList.find(route=>to.path.includes(route) && !(['/','/search-company','/search-capital'].indexOf(to.path) === -1))
        if (whiteList.indexOf(to.path) !== -1 || find) {
            // 在免登录名单则直接前往
            if(to.path === '/login' && !to.query.redirectFull){
                next({path: `/login`, query: {
                    redirectFull: JSON.stringify(window.location.href + (!window.location.href.includes('?') ? '?sDW=1' : '&sDW=1'))}
                })
            }
            else next()
        } else if(hasCookie){
            // 有cookie 但无本地缓存
            console.log(hasCookie,'是否有cookie');
            next()
        } else {
            // 其他需要登录的页面则先跳转到登录页面再进行跳转
            next({path: `/login`, query: {
                redirectFull: JSON.stringify(window.location.href + (!window.location.href.includes('?') ? '?sDW=1' : '&sDW=1'))}
            })
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    // 进度条完成
    NProgress.done()
})