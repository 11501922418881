<template>
  <div class="box">
    <div class="header" id="header-main">
      <img class="logo" src="@/assets/images/LOGO.png" />
      <img @click="showMenu" class="menu-icon" src="@/assets/images/mobile/menu.png" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    showMenu() {
      this.$router.push({
        path: '/mobile-menu',
        query: { currentPath: this.$route.path }
      })
    },
  },
  mounted() {
    this.$nextTick(()=>{
      window.onscroll = function() {
        var headerMain = document.getElementById('header-main');
        if(window.pageYOffset >= 70){
          if(headerMain) headerMain.classList.add('headerMain-bg');
        } else {
          if(headerMain) headerMain.classList.remove('headerMain-bg');
        }
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  .headerMain-bg {
    background: rgba(0,0,0,0.8);
  }
  .header {
    width: 10rem;
    padding: 0.37rem 0.43rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    .logo {
      width: 2.47rem;
      height: 0.88rem;
    }
    .menu-icon {
      width: 0.71rem;
      height: 0.64rem;
    }
  }
}
</style>
