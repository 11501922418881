import {
  post, service,
} from "@/utils/request";
import store from '@/store'
import { requestVersion,chatLimit } from "@/utils/consts.js"
import { TanSite,AISite } from "@/router/index";
import { getTanId } from "@/utils/Tan/login";
import { getAIId } from "@/utils/AI/login";

// 查看权限次数
export function getUserRightTimes(id){
  return post('/jurisdiction/jurisdictionCount',{
    "memberId": id
  })
}

// 标记已读
export function setLeaveMessageRead(params){
  const param = {
    "chatId": params.chatId,
  }
  return post('/message/readFlags',param)
}

// 获取留言未读数量
export function getUnreadLeaveMessageNum(){
  const param = {
    "memberId": store.getters.id + '',
  }
  return post('/message/getNotReadCount',param)
}

// 获取留言列表
export function getLeaveMessageList(params){
  const param = {
    "limit": params.limit,
    "memberId": store.getters.id + '',
    "offset": params.offset
  }
  return post('/message/messageList',param)
}


// 发布留言
export function sendLeaveMessage(params){
  const param = {
    "chatMsg": params.chatMsg,
    "chatType": params.chatType,  // 留言类型 0-专家、1-公司,可用值:0,1
    "fromIm": params.fromName,
    "fromMemberId": store.getters.id + '',
    "msgType": params.msgType,  // 0-文本类型消息 \n 1-图片类型消息\n 2-地址位置类型消息\n 3-语音类型消息\n 4-视频类型消息\n 5-文件类型消息
    "toHeadImg": params.headImage,
    "toIm": params.name,
    "toMemberId": params.id
  }
  return post('/message/sendMessage',param)
}

// 获取留言详情
export function getLeaveMessageInfo(params){
  const param = {
    "chatId": params.id,
    "limit": chatLimit,
    "offset": params.offset
  }
  return post('/message/messageInfo',param)
}

// 获取用户审核状态
// 0 : 未审核
// 1 : 审核中
// 2 : 审核成功
// 3 : 审核失败
export function getUserVerifyStatus(param){
  const params = {
    "memberId": param.id
  }
  return post('/organization/verifyStatus',params)
}

// 检查权限1.11.3 新增
export function checkUserDiction(param,isSaveCountRecord = false,id){
  let u_id = store.getters.id
  if(TanSite) u_id = getTanId()
  if(AISite) u_id = getAIId()
  const params = !isSaveCountRecord ? {
    "channel": "pc",
    "id": id ? id : '',
    "memberId": u_id,
    "isSaveCountRecord": false,
    "uniqueLab": param.uniqueLab,
    "version": requestVersion
  }:{
    "channel": "pc",
    "memberId": u_id,
    "id":id,
    "uniqueLab": param.uniqueLab,
    "isSaveCountRecord": isSaveCountRecord,
    "version": requestVersion
  }
  return post('/jurisdiction/checkJurisdiction',params)
}


// 删除邮件
export function deleteUserEmail(param){
  const params = {
    "id": param.id,
  }
  return post('/organization/remove',params)
}

// 获取随机信息
export function getRandomMessages(){
  return post('/config/randomBroadcastingConfig',{})
}

// 获取邮件详情
export function getUserEmailDetail(param){
  const params = {
    "id": param.id,
  }
  return post("/organization/emailInfo",params)
}

// 获取发送者的邮件列表 type 邮件类型 1给公司发 0给老师发 2老师回复 3公司回复 默认1 status 状态 0未读 1已读
export function getUserEmailList(param){
  let id = store.getters.id
  if(TanSite) id = getTanId()
  const params = {
    "limit": 20,
    "memberId": id,
    "offset": param.offset
  }
  return post("/organization/emailList",params)
}

// 添加用户定制权益
export function addUerPermissions(data){
  const param = {
    "memberId": store.getters.id,
    "businessProfile": data.businessProfile,
    "industry": data.industry,
    "name": data.name,
    "phone": data.phone,
    "unit": data.unit
  }
  return post("/customizeMemberPermissions/add",param)
}

// 获取用户定制权益
export function getUserPermissions(id){
  const param = {
    "memberId": store.getters.id,
    "organizationId": id
  }
  return post("/customizeMemberPermissions/getInfo",param)
}



// 埋点接口
export function trackingUser(data,type){
  const param = {
    "memberId": store.getters.id,
    "paramJson": data,
    "typeStr": type
  }
  return post("/buryingPoint/save",param)
}


// vip 试用
export function vipProbation(data){
  return post('/activityJoin/vipOnTrial',data)
}

// 后台自动生成的公司信息
export function autoCompanyAdd(data){
  return post('/enterprise/vipModifyCompany',data)
}


// 用户是否已经完善公司信息
export function memberIsPerfect(memberId){
  const param = {
    "id": memberId
  }
  return post('/member/findMemberIsPrefect',param)
}
// 每天首次访问发送问候消息
export function everydayGreet(memberId){
  const param = {
    "memberId" : memberId
  }
  return post('/log/sendGreetMsg',param)
}


// 检查手机号是否存在 true存在 false不存在
export function checkPhone(phone){
  const param = {
    "phone": phone
  }
  return post('/member/checkPhoneExistence',param)
}

// QQ登录
export function QQLogin(accessToken,expireIn,type,phone,captcha,useQqInfo){
  let params = {}
  if(type === 9){
    params = {
      "accessToken": accessToken,
      "canal": 0, // pc
      "expireIn": expireIn,
      "machineCode": "",
      "type": type,
      "useQqInfo": false,
      "version": 1.9
    }
  }
  else{
    params = {
      "accessToken": accessToken,
      "canal": 0, // pc
      "expireIn": expireIn,
      "machineCode": "",
      "type": type,
      "phone": phone,
      "code": captcha,
      "useQqInfo": useQqInfo,
      "version": 1.9
    }
  }
  return post('/member/sign/qqSignIn',params)
}

// 微信登录
export function weChatLogin(code,type,phone,captcha,flag){
  let params = {}
  if(type === 6){
    params = {
      "canal": 0, // pc
      "code": captcha,
      "flag": flag,
      "phone": phone,
      "machineCode": "",
      "type": type, // 微信登录
      "version": 1.9,
      "wxCode": code
    }
  }
  else{
    params = {
      "canal": 0,
      "flag": false,
      "machineCode": "",
      "type": type, // 微信登录
      "version": 1.9,
      "wxCode": code
    }
  }
  return post('/member/sign/wxLogin', params)
}

export function userPermission(userId,route){ // 用户权限查询
  // const pageParam = JSON.stringify(route.query) !== "{}" ? [] : []
  const params = {
    "canalName": "pc",  // 访问端
    "memberId": userId ? userId : store.getters.id,
    "pageParam": [],  // 页面参数
    "pagePath": route.fullPath
  }
  return post('/member/permission/check',params)
}

export function customService(name,phone){  // 私人定制接口
  const params = {
    "contactWay": phone,
    "memberName": name
  }
  return post('/activityJoin/tailorMade',params)
}

export function getPhoneCode(data) { // 获取短信验证码
  return post('/sms/login/send', data)
}


export function login(data) { // 登录
  return post('/member/sign/signIn', data)
}

export function refreshToken(data) {
  return post('/member/token/getToken', data)
}

export function enterpriseInfo(id) {
  return post('/enterprise/findByOrganizationId', {id})
}

// 根据id获取用户组织信息
export function memberIdForOrgInfo(id) {
  return post('/member/findOrganizationByMemberId', {id})
}

// 根据id获取详细个人信息
export function memberDetails(id) {
  return post('/member/getDetailedByMemberId', {id})
}

// 随机获取企业20个
export function getRandomEnterprise(keyword,id){
  const param = {
    "keyword": keyword,
    "memberId": id
  }
  return post('/enterprise/randomCompany',param)
}

// 数字科创删除员工
export function removeMember(id){
  const param = {
    "adminId": store.getters.id,
    "memberId": id
  }
  return post('/enterprise/kickOutMember',param)
}

// 实验室老师移除助手
export function removeAssistant(id){
  const param = {
    "assistantId": id,
    "teacherId": store.getters.id
  }
  return post('/member/assistant/removeAssistant',param)
}
// 根据助手ID获取列表
export function findTeacherAssistantByAssistantId(assistId){
  return post('/member/assistant/listTeacherAssistant',{id:assistId})
}

// 根据ID获取助手列表
export function findTeacherAssistant(teacherId){
  return post('/member/assistant/listAllAssistant',{teacherId:teacherId})
}

// 非产学研用户 修改资料
export function editProfileBasic(data){
  return post('/member/modifyMemberBasic', data)
}

// 修改资料 产学研
export function editProfile(data) {
  return post('/member/updateMemberMessage', data)
}


// 登录后绑定行业(多选)
export function bindIndustry(params) {
  let ids = params.industryIds
  const preselectionIndustryIds = params?.preselectionIndustryIds?.length > 0 ? params.preselectionIndustryIds : []
  const preselectionOrganizationTypeId = params?.preselectionOrganizationTypeId || ''
  let param = {    // 接口只需要传入行业id即可
    industryIds: ids,
    preselectionIndustryIds,
    memberId: store.getters.id
  }
  if(preselectionOrganizationTypeId){
    param.preselectionOrganizationTypeId = preselectionOrganizationTypeId
  }
  return post('/member/memberBindingIndustry', param)
}


// 绑定实验室
export function bindLab(data) {
  return post('/organization/save', data)
}

// 旧的绑定公司
// export function bindOrg(data) {
//   return post('/organization/modifyCompany', data)
// }

// 修改实验室信息
export function editLab(data) {
  return post('/organization/updateOrganization', data)
}

//修改实验室信息(new))
export function editLabNew(data) {
  return post('/lab/updateOrganization', data)
}

// 公司更新信息(1.6.1)
export function editOrg(data) {
  return post('/enterprise/modify', data)
}

// 创建公司
export function createOrg(data) {
	return post('/enterprise/addCompany', data)
}

// 通过企业ID查询审核状态
export function enterpriseAuditStatus(enterpriseId){
  return post('/enterprise/isVerify', enterpriseId)
}

// 通过用户ID查询组织机构表的审核状态
export function orgAuditStatus(userId){
  return post('/member/findOrganizationStatus', userId)
}

// 根据id查询基本个人信息
export function memberMessage(id) {
  return post('/member/findMemberMessage', {id})
}

// 根据id查询用户行业
export function memberIndustryMessage(id) {
  return post('/member/findMemberIndustry', {id})
}

export function memberMessageDetail(id) {
  return post('/member/findMemberMessageDetail', {id})
}

// 关注专家
export function subscribeDbExpert(param) {
  return post('/member/subscribe/addFollowExpert', {
    "dbStatus": 0,
    "memberId": store.getters.id,
    "subscribeHeadImg": param.headImage,
    "subscribeId": param.id,
    "subscribeName": param.name
  })
}


// 订阅用户
export function subscribeUser(id) {
  return post('/member/subscribe/addFollow', {
    "memberId": store.getters.id,
    "subscribeId": id,
    "subscribeType": 0
  })
}


// 订阅组织
export function subscribeOrg(id) {
  return post('/member/subscribe/addFollow', {
    "memberId": store.getters.id,
    "subscribeId": id,
    "subscribeType": 1
  })
}
// 取消所有订阅组织
export function unsubscribeAllOrgs() {
  return service.request({
    url: '/member/subscribe/deleteAllFollow',
    data: {
      "memberId": store.getters.id,
      "subscribeType": 1
    },
    method: "DELETE"
  })
}

// 取消所有订阅用户
export function unsubscribeAllUser() {
  return service.request({
    url: '/member/subscribe/deleteAllFollow',
    data: {
      "memberId": store.getters.id,
      "subscribeType": 0
    },
    method: "DELETE"
  })
}


// 取消订阅用户
export function unsubscribeUser(id,type=0) {
  let u_Id = store.getters.id
  if(TanSite) u_Id = getTanId()
  return service.request({
    url: '/member/subscribe/deleteFollow',
    data: {
      "memberId": u_Id,
      "subscribeId": id,
      "subscribeType": type
    },
    method: "DELETE"
  })
}


//取消订阅组织
export function unsubscribeOrg(id) {
  return service.request({
    url: '/member/subscribe/deleteFollow',
    data: {
      "memberId": store.getters.id,
      "subscribeId": id,
      "subscribeType": 1
    },
    method: "DELETE"
  })
}


// 查询关注状态
export function userSubscribeStatus(id) {
  return post('/member/subscribe/findMemberWhetherAttention', {
    "memberId": store.getters.id,
    "subscribeId": id,
    "type": 0
  })
}

export function orgSubscribeStatus(id) {
  return post('/member/subscribe/findMemberWhetherAttention', {
    "memberId": store.getters.id,
    "subscribeId": id,
    "type": 1
  })
}

// 分页获取关注列表(包含专家)
export function getUserSubscribedDb(page, pageSize) {
  let id = store.getters.id
  if(TanSite) id = getTanId()
  return post('/member/subscribe/getFollowExpertList', {
    subscribeTypes: [0,3,4],       // 0-会员,1-组织机构 2实验室 3导入专家 4在线专家
    offset: page,
    memberId: id,
    limit: pageSize
  })
}

// 分页获取关注列表
export function getUserSubscribed(page, pageSize) {
  return post('/member/subscribe/getFollowList', {
    subscribeType: 0,       // 0-会员,1-组织机构,2-仅实验室
    offset: page,
    memberId: store.getters.id,
    limit: pageSize
  })
}

// 分页获取关注实验室列表
export function getLabSubscribed(page, pageSize) {
  return post('/member/subscribe/getFollowList', {
    subscribeType: 2,       // 2-仅实验室
    offset: page,
    memberId: store.getters.id,
    limit: pageSize
  })
}
// 分页获取屏蔽用户
export function getUserblocked(page, pageSize) {
  return post('/member/subscribe/getBlockList', {
    subscribeType: 0,
    offset: page,
    memberId: store.getters.id,
    limit: pageSize
  })
}

// 全局用户模糊搜索
export function memberSearch(keyword) {
  return post('/member/search', {
    memberId: store.getters.id,
    keyword
  })
}

// 检查普通用户个人信息是否填写完整
export function checkInfoComplete() {
  return post('/member/checkInfoComplete', {
    id: store.getters.id
  })
}

// 普通用户完善个人信息
export function perfectMemberInfo({ belong, educationId, jobPosition, name }) {
  return post('/member/perfectMemberInfo', {
    id: store.getters.id,
    belong,
    educationId,
    jobPosition,
    name
  })
}


export function saveFoot(params){
  return post('/member/footprint/saveFootprint',params)
}

export function getFoot(params){
  return post('/member/footprint/pageFootprint',params)
}

export function deleteFoot(params){
  return post('/member/footprint/delFootprint',params)
}

export function dailyLog(params){
  return post('/daily/log/save',params)
}

export function judgeBusinessVip(params){
  return post('/specialVip/isBusinessSchoolVip',params)
}

export function complainUser(params){
  return post('/member/complaint/insert',params)
}