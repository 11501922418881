import {post} from "@/utils/request";
import store from '@/store'


export const educationType = 4
export const positionType = 5
export const industryType = 6
export const orgServeType = 11
export const userServeType = 12
export const publishType = 13   // 动态类型
export const reasonType = 7
export const labPositionType = 10
export function getOptionsByType(typeName, limit, offset) {
    if (store.getters.organizationType === 0 && typeName === positionType) {
        typeName = labPositionType
    }
    return post('/config/getSelectByType', {
        type: typeName,
        limit: limit || 1 << 20,
        offset: offset || 1
    })
}
export function selectHaveIndustryServer() {
    return post('/server/selectHaveIndustryServer', {
    })
}

export function getLabels() {
    return post('/config/listAchievementLabel', {
        memberId: store.getters.id
    })
}

export function getSelectFatherSonByType(type) {
    return post('/config/getSelectFatherSonByType', {
        type
    })
}

export function getListConfigsByTypes(type){
    const params = {
        "types": [type]
    }
    return post('/config/listConfigsByTypes', params)
}

export function getSelectByPid(id){
    const params = {
        id
    }
    return post('/config/getSelectByPid', params)
}
