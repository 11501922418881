// 从v2.11.2起，SDK 支持了 WebSocket，推荐接入；v2.10.2及以下版本，使用 HTTP
import TIM from 'tim-js-sdk'
import COS from 'cos-js-sdk-v5'
import { ElMessage } from "element-plus";
import { environment } from "../utils/consts"

let options = {
  SDKAppID: 1400565273
}

export let tim = TIM.create(options) // 创建SDK实例，同一个 `SDKAppID` 只会返回同一份实例

tim.setLogLevel(environment === 'uat' ? 0 : 1) // 设置 SDK 日志输出级别, 0 普通级别，日志量较多; 1 release级别,输出关键信息，生产环境时建议使用

tim.registerPlugin({'cos-js-sdk': COS})  //注册腾讯云即时通信 IM 上传插件


// 登录腾讯云im
export function timLogin(param) {
  let { userID, userSig } = param
  localStorage.setItem('userId', userID)
  localStorage.setItem('userSig', userSig)

  return new Promise((resolve, rejected)=>{
    tim.login({ userID, userSig }).then((imResponse) => {
      console.log('tim login:', imResponse)
      if(imResponse.data.repeatlogin === true) {
        // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
        console.error('tim repeat login error:',imResponse.data.errorInfo)
      }
      resolve(true)
    }).catch((imError) => {
      rejected(false)
      ElMessage.error(imError)
    })
  })
}

export default TIM
export const SDKAppID = 1400565273
// 发送消息后更新个人资料给腾讯云
import { staticPath2,talentProjectSystemPage } from "@/utils/consts"
import { G60site,TanSite,PostLineSite,AISite } from "@/router/index";
import { getLocalInfo } from "@/utils/talent-project-system/login";
import { getG60LocalInfo } from "@/utils/G60/login";
import { getTanLocalInfo } from "@/utils/Tan/login";
import { getAILocalInfo } from "@/utils/AI/login";
import { getPLLocalInfo } from "@/utils/post-line/login";

export function updateProfile(data) {
  // 登录后更新腾讯云个人资料
  let userinfo = JSON.parse(localStorage.getItem('userInfo'))
  let avatar = ''
  let name = ''
  const isTPS = talentProjectSystemPage.find((path)=>{
    return window.location.href.includes(path)
  })
  const isPL = PostLineSite
  if(isTPS){
    userinfo = getLocalInfo()?.memberInfo
    avatar = userinfo.headImage
    name = userinfo.name
  }
  else if(G60site){
    userinfo = getG60LocalInfo()?.memberInfo
    avatar = userinfo.headImage
    name = userinfo.name
  }
  else if(TanSite){
    userinfo = getTanLocalInfo()?.memberInfo
    avatar = userinfo.headImage
    name = userinfo.name
  }
  else if(AISite){
    userinfo = getAILocalInfo()?.memberInfo
    avatar = userinfo.headImage
    name = userinfo.name
  }
  else if(isPL){
    userinfo = getPLLocalInfo()?.memberInfo
    avatar = userinfo.headImage
    name = userinfo.name
  }
  else{
    avatar = userinfo.avatar
    name = userinfo.name
  }
  let personOption = {
    nick: name,
    avatar: staticPath2 + avatar
  }
  if(data){
    personOption.nick = data.name // 普通用户个人信息填写的名字
  }
  personOption.avatar = checkAvatar(personOption.avatar)
  tim.updateMyProfile(personOption)
}
export function checkAvatar(avatar){  // 检查格式
  let index = avatar.indexOf('.com')
  if(avatar[index + 4] !== '/'){
    avatar = avatar.slice(0, index + 4) + '/' + avatar.slice(index + 4);
  }
  return avatar
}