export function getLocalTime (nS) {
   return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ')
}

export function initTime(){ // 生成当前时间的时间戳
    const date = new Date();
    const now = {
        year : date.getFullYear(),
        month : date.getMonth() + 1,
        day: date.getDay(),
        hour: date.getHours(),
        minute: date.getMinutes(),
        second: date.getSeconds()
    }
    return JSON.stringify(now)
}
// 获取完整的时间
export function formatFullTime(timestamp){
    //日期时间处理
    function cover(s) {
        return s < 10 ? '0' + s : s;
    }
    //let myDate = new Date()如果是要获取当前时间的显示就不传参数
    let myDate = new Date(timestamp);  //获取js时间
    let year = myDate.getFullYear(); //获取年
    let month = myDate.getMonth() + 1;//获取月
    let date = myDate.getDate();//获取日
    let  h = myDate.getHours(); //获取小时数(0-23)
    let m = myDate.getMinutes(); //获取分钟数(0-59)
    let s = myDate.getSeconds();
    //获取当前时间连接成的字符串
    let now = year + '-' + cover(month) + "-" + cover(date) + " " + cover(h) + ':' + cover(m) + ":" + cover(s);
    return now
 }

// Date转化为YYYY-MM-DD
export function dateFormat (d, sign) {
    // 如果没有传递符号，给一个默认的符号
    if (!sign) sign = '-'
    const year = d.getFullYear()
    const month = d.getMonth() + 1
    const sun = d.getDate()
    const temp = year + sign + this.mendZero(month) + sign + this.mendZero(sun)
    return temp
}

// hh:mm
export function dateFormatToHour (d) {
    const hour = d.getHours()
    const minute = d.getMinutes()
    const temp = this.mendZero(hour) + ':' + this.mendZero(minute)
    return temp
}

// XXXX-MM-DD hh:mm
export function dateFormatToComplete (dateTimeStamp) {
    var minute = 1000 * 60;
    var hour = minute * 60;
    // var day = hour * 24;
    // var month = day * 30;
    var now = new Date().getTime()
    var diffValue = now - dateTimeStamp;
    if (diffValue < 0) {    // 防止未定义
        diffValue = 0
    }
    // var monthC = diffValue / month;
    // var weekC = diffValue / (7*day);
    // var dayC = diffValue / day;
    var hourC = diffValue / hour;
    if (hourC < 12) { // 如果是超过12个小时显示hh:mm
        let hour = dateTimeStamp.getHours()
        if(hour < 10) hour = '0' + hour
        let minute = dateTimeStamp.getMinutes()
        if(minute < 10) minute = '0' + minute
        const temp = hour + ':' + minute
        return temp
    } else { // 否则显示年月日
        const year = dateTimeStamp.getFullYear()
        const day = dateTimeStamp.getDate()
        const month = dateTimeStamp.getMonth() + 1
        const temp = year + '/' + this.mendZero(month) + '/' + this.mendZero(day)
        return temp
    }
}

// 时间转换补零
export function mendZero (num) {
    return (num = num < 10 ? '0' + num : num)
}

function ljust(number) {
    try {
        let ns = number.toString()
        return ns.length === 1 ? '0' + ns : ns
    } catch (e) {
        console.error('just time error:',e)
    }
}
export function timeFromList(l) {
    if (l) {
        if (l.length === 6) { // 补0
            return `${l[0]}-${Number(l[1]) >= 10 ? l[1] : '0' + l[1]}-${Number(l[2]) >= 10 ? l[2] : '0' + l[2]} ${Number(l[3]) >= 10 ? l[3] : '0' + l[3]}:${Number(l[4]) >= 10 ? l[4] : '0'+  l[4]}:${Number(l[5]) >= 10 ? l[5] : '0' + l[5]}`
        } else if (l.length === 5) {
            return `${l[0]}-${ljust(l[1])}-${ljust(l[2])} ${ljust(l[3])}:${ljust(l[4])}`
        } else if (l.length === 4) {
            return `${ljust(l[0])}-${ljust(l[1])} ${ljust(l[2])}:${ljust(l[3])}`
        } else if (l.length === 3) {
            return `${l[0]}-${ljust(l[1])}-${ljust(l[2])}`
        }
        else{
            return l
        }
    }
}

export function randomFilename() {
    let now = new Date()
    return `${now.getFullYear()}/${ljust(now.getMonth())}/${ljust(now.getDate())}/${Math.floor(now.valueOf() / 1000)}${Math.floor(Math.random() * 1000000)}`
}