import Cookies from 'js-cookie'
import store from "../store"
import { memberDetails } from '../api/user'
import { ElMessage } from 'element-plus'
import { initTime } from "../utils/time"
import { routerWindow,getLocalUserId } from "@/utils/tool";
import { TanBaseUrl } from "@/utils/consts";
import { TanMemberInfo } from "@/api/Tan";

const TokenKey = 'Admin-Token'
const userInfoKey = "userInfo"


export function setLoginTime(){ // 设置登录时间戳
  localStorage.setItem("loginTime", initTime())
}

export function getLoginTime(){ // 获取登录时间戳
  return localStorage.getItem("loginTime") ? JSON.parse(localStorage.getItem("loginTime")) : undefined
}

export function setUserInfo(userInfo) {
  localStorage.setItem(userInfoKey, JSON.stringify(userInfo))
  if(!getLoginTime()) setLoginTime() // 判断是否在时间戳中保存了时间，如果没有获取当前的时间进行保存
}


export function getToken() {
  let userInfos = JSON.parse(localStorage.getItem(userInfoKey))
  return userInfos ? userInfos.accessToken : ""
}


export function getUserInfo() {
  let userInfos = JSON.parse(localStorage.getItem(userInfoKey))
  return userInfos ||
    {
      id: -1,
      accessToken: "",
      name: "",
      avatar: "",
      industryId: [], // 多选，改用数组类型
      industry: '',
      imEasemobUsername: "",
      imPassword: "",
      organizationId: null,
      organizationName: ''
    }
}


export function removeUserInfo() {
  localStorage.removeItem(userInfoKey)
  localStorage.removeItem('userInfos')
}


// export function setToken(token) {
//   return Cookies.set(TokenKey, token)
// }


export function removeToken() {
  // var exp = new Date();
  // exp.setTime(exp.getTime()-10000);
  // let cookieObj = cookieToJson()
  // const domain = location.hostname
  // let cookieNames = []
  // for(var key in cookieObj) {
  //   cookieNames.push(key)
  // }
  // if (cookieNames) {
  //   for (var i =  cookieNames.length; i--;)
  //     document.cookie=cookieNames[i]+"=0; expire="+exp.toGMTString()+"; path=/"+"; domian="+domain;
  // }
  // setTimeout(()=>{
  // },1000)
  
  return Cookies.remove(TokenKey)
}

// function cookieToJson() {
//   let cookieArr = document.cookie.split(";");
//   let obj = {}
//   cookieArr.forEach((i)=>{
//     let arr = i.split("=");
//     obj[arr[0]] =arr[1];
//   })
//   return obj
// }

export function checkVip(type = 0){
  if(!localStorage.getItem('userInfo')) return false
  let {level, organizationVipEndTime, organizationType} = getUserInfo()
  // 非VIP点击直接弹出VIP购买提示
  let { isVip } = JSON.parse(localStorage.getItem('userInfo'))
  if(!isVip || !((organizationType === 1 && organizationVipEndTime) || level > 0)){
    if(type === 1){
      store.dispatch("message/hideIMWindow")
      store.commit('message/SET_IM_WINDOW_DIALOG', true)
    }
    return false
  }
  return true
}

export async function pathJump(path,query,checkSelf){ // 路由跳转 checkSelf 为判断是否需要自我检查，例如路演时主持人点击自己的头像跳转
  let res = ''
  let userInfo = localStorage.getItem('userInfo')
  if(userInfo){
    userInfo = JSON.parse(userInfo)
  }
  switch (path) {
    case '/my-lab':
      if(!query || !query.memberId) return  // 空判断
      if(checkSelf && userInfo && query.memberId == userInfo.id) return // 自我判断
      res = await memberDetails(userInfo.id)   // 获取当前用户会员信息
      if(res.data.isVip == false){  // VIP 判断
        store.dispatch("message/hideIMWindow")
        store.commit('message/SET_IM_WINDOW_DIALOG', true)
        return
      }
      res = await memberDetails(query.memberId)   // 获取用户的组织信息
      if(res.data.organizationType === 0){    // 实验室
        routerWindow(this.$router,path,{id: res.data.id})
      }
      else if(res.data.organizationType === 1){
        if(res.data.organizationId && res.data.organizationName.startsWith(res.data.phone)){   // 付费无组织用户
          routerWindow(this.$router,'/my-lab',{id: res.data.id})
        }
        else {  // 企业用户
          routerWindow(this.$router,'lab',{id: res.data.organizationId, identity: 1})
        }
      }
      else {  // 无组织用户
        ElMessage.warning('此用户暂未创建实验室或企业!')
      }
      break;
    default:
      break;
  }
}

export function judgeNavbar(scroll,scrollTop,route){  // 判断是否显示完整的navbar scroll < 0 向下 scroll > 0 向上
  let noMiddle = ['/search-center','/user-center','/detail']
  if(noMiddle.includes(route.path)) store.commit('user/SET_SHOW_MIDDLE', false)
  else store.commit('user/SET_SHOW_MIDDLE', true)
  if(scroll < 0 && scrollTop > 100){
    store.commit('user/SET_SHOW_TOP', false)
  }
  else if(scroll >= 0 && scrollTop <= 80){
    store.commit('user/SET_SHOW_TOP', true)
  }
}


export function tanVipJudge(params){
  const { pRes } = params
  const id = getLocalUserId()
  if(!id){
    console.log('无用户ID')
    return
  }
  const handleData = ()=>{
    // 先判断用户身份类型
    TanMemberInfo(id).then(res=>{
      if(res.data?.tjCompanyId){
        // 已创建
        window.open(TanBaseUrl + '/p-center')
      }
      else{
        // 未创建
        window.open(TanBaseUrl + '/identity')
      }
    })
  }
  if(pRes){
    handleData(pRes)
  }
  else{
    memberDetails(id).then(res=>{
      handleData(res)
    })
  }
}

export function tanJudge(){
  const id = getLocalUserId()
  if(!id){
    console.log('无用户ID')
    return
  }
  TanMemberInfo(id).then(res=>{
    if(res.data?.tjCompanyId){
      // 已创建
      window.open(TanBaseUrl + '/p-center')
    }
    else{
      // 未创建
      window.open(TanBaseUrl + '/identity')
    }
  })
}