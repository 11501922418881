<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "BreadCrumb",
  computed: {
    ...mapGetters(['breadcrumbItems']),
  },
  data() {
    return {
      secondaryPage: ['/enterpriseDynamic','/searchDynamic','/search','/user-center','/search-demand'],
      thirdPage: ['/searchdetail','/newsDetail','/my-lab-doctor','/my-lab', '/search-company','/demand-detail'],  // 详情页
      lists:[]            // 定义一个数组 用于接收路由信息
    }
  },
  created(){
    this.lists.push({ // 初始化
      path: '/',
      meta: {
        breadTitle: '首页'
      }
    })
    this.$store.dispatch('breadcrumb/setItems',this.lists)
  },
  watch: {
    $route(to,from) {  // 监听路由
      if(!to.meta.breadTitle) return // 无面包屑
      this.lists = this.breadcrumbItems // 重新赋值防止延迟
      let map = new Map()
      this.lists = this.lists.filter((item) => !map.has(item.meta.breadTitle) && map.set(item.meta.breadTitle, 1))  // 去重
      let index = -1
      let repeat = -1  // 判断是否重复页面
      this.lists.find((item,i)=>{
        if(item.meta.breadTitle === to.meta.breadTitle && to.path !== '/search-company'){  // 根据title进行判断
          index = i
          return true
        }
        else if(this.checkSecond(item.path,to.path)){  // 二级路径判断
          index = i - 1
          repeat = 1
          return true
        }
        else if(this.checkThird(item.path,to.path)){  // 三级路径判断
          index = i - 1
          if(i !== 1) repeat = 2
          else if(i === 1) repeat = 1 // 首页进详情
          return true
        }
      })
      if(index !== -1 && repeat === -1){ // 新页面判断
        this.lists = this.lists.slice(0,index + 1)
      }
      else if(index !== -1 && repeat !== -1){  // 路由重复,截取后再添加
        this.lists = this.lists.slice(0,repeat)
        this.checkCompany(to,from)
      }
      else{
        this.checkCompany(to,from)
      }
      this.$store.dispatch('breadcrumb/setItems',this.lists)
    }
  },
  methods:{
    setType(to){  // 设置搜企业页面内部对应的类型
      if(to.query.type == 0 || !to.query.type){
        this.lists.push({
          path: to.path,
          meta: {
            breadTitle: to.meta.breadTitle
          }
        })
      }
      else if(to.query.type == 1){
        this.lists.push({
          path: to.path,
          meta: {
            breadTitle: '搜园区'
          }
        })
      }
      else if(to.query.type == 2){
        this.lists.push({
          path: to.path,
          meta: {
            breadTitle: '搜资本'
          }
        })
      }
    },
    checkCompany(to,from){ // 修改搜企业页面的面包屑
      if(to.path === '/search-company' && (from.path === '/search-company' || from.path === '/')){  // 是搜企业页面且从相同的页面路由来到或刷新时
        this.setType(to)
      }
      else if(to.path === '/search-company' && from.path !== '/user-center' && from.path !== '/'){ // 到搜企业页面且不是首页或者工作中心 (后退等操作时)
        if(this.lists.length === 1){
          this.setType(to)
        }
        else if(this.lists.length === 2){
          this.lists[1] = {
            path: '/user-center',
            meta:{
              breadTitle: '工作中心'
            }
          }
          this.setType(to)
        }
        else if(this.lists.length === 3){
          this.lists = this.lists.slice(0,2)
          this.setType(to)
        }
      }
      else{
        this.lists.push({
          path: to.path,
          meta: {
            breadTitle: to.meta.breadTitle
          }
        })
      }
    },
    checkSecond(item,to){ // 二级路径重复判断
      if(this.secondaryPage.includes(item) && this.secondaryPage.includes(to)) return true
      return false
    },
    checkThird(item,to){  // 三级路径重复判断
      if(this.thirdPage.includes(item) && (this.secondaryPage.includes(to) || this.thirdPage.includes(to))) return true
      return false
    }
  }
}
</script>

<style scoped>
</style>