import { staticPath, videoStaticPath, recommendChannel, kqdPath,requestVersion,talentProjectSystemPage } from "@/utils/consts";
import { G60site,TanSite,PostLineSite,AISite } from "@/router/index";
import { trackingUser, userPermission, memberDetails } from "@/api/user";
import { getOrganizationTypes } from "@/api/organization";
import { getlistHomepage } from "@/api/dynamic";
import { demandDetail } from "@/api/demand";
import { ElMessage } from "element-plus";
import { getDynamicDetail } from "@/api/dynamic";
import store from '@/store';
import to from 'await-to-js';
import router from '../router/index';
import { searchMapInfo } from "@/api/search";
import { getTanId } from "@/utils/Tan/login";
import { getAIId } from "@/utils/AI/login";


export function isObject(o) {  // 判断是不是数组和对象
    return Object.prototype.toString.call(o) === '[object Array]' || Object.prototype.toString.call(o) === '[object Object]'
}

export function objIsEmpty(o) { // 判断是否空对象
    return Object.keys(o).length === 0 && Object.getOwnPropertySymbols(o).length === 0
}

export function deepClone(o, hash = new Map()) {   // 实现深拷贝函数 利用Map数据结构来解决循环引用造成死循环的问题
    if (!isObject(o)) { // 判断类型，只适用于array和object
        return o
    }
    if (hash.has(o)) { // 判断循环引用
        return hash.get(o)
    }
    const obj = Array.isArray(o) ? [] : {}
    hash.set(o, obj)
    for (const k in o) {
        if (isObject(o[k])) {
            obj[k] = deepClone(o[k], hash)
        } else {
            obj[k] = o[k]
        }
    }
    return obj
}

export function throttle(fn, delay) {   // 节流
    let timer = null
    let startTime = Date.now()
    return function () {
        let curTime = Date.now() // 当前时间
        let remaining = delay - (curTime - startTime)  // 从上一次到现在，还剩下多少多余时间
        let context = this
        let args = arguments
        clearTimeout(timer)
        if (remaining <= 0) {
            fn.apply(context, args)
            startTime = Date.now()
        } else {
            timer = setTimeout(fn, remaining);
        }
    }
}

export function debounce(fn, time) {    // 防抖
    let timer = null // 定时器对象
    return function () {
        // 重新计算时间: 清除定时器，启动新的定时器任务
        clearTimeout(timer)
        timer = setTimeout(function () {
            fn()
        }, time)
    }
}

export function copyToClipboard(text) { // 复制内容到剪切板
    const copyWords = Object.prototype.toString.call(text) == "[object String]" ? text : JSON.stringify(text)
    navigator.clipboard.writeText(copyWords);
}

export function setTitle(val) { // 设置title
    let titDom = null;
    titDom = document.querySelector('title');
    if (titDom) {
        titDom.innerText = val;
    }
}

export function setKeywords(val) {  // 设置 keywords
    let keyDom = null;
    keyDom = document.querySelector('meta[name="keywords"]')
    if (keyDom) {
        keyDom.setAttribute("content", val);
    }
}

export function setDescription(val) {   // 设置 description
    let desDom = null;
    desDom = document.querySelector('meta[name="description"]')
    if (desDom) {
        desDom.setAttribute("content", val)
    }
}

export function imgSrcSplitN(src) {   // 图片拼接
    if (!src) return ''  // 无字段返回默认图
    if (src.indexOf('http') !== -1) return src   // 已经带前缀
    return staticPath + src // 补充前缀
}

export function imgSrcSplit(src, defaultImg = require('../assets/images/square-avatar.png')) {   // 图片拼接
    if (!src || src === 'https://images.weserv.nl/?url=') return defaultImg  // 无字段返回默认图
    if (src.indexOf('http') !== -1) return src   // 已经带前缀
    return staticPath + src // 补充前缀
}

export function videoSourceSplit(src) {   // 视频拼接
    if (!src) return false  // 无字段返回
    if (src.indexOf('http') !== -1) return src
    return videoStaticPath + src
}


export function getType(param) {    // 返回数据类型
    return Object.prototype.toString.call(param)
}

export function isJson(str) {  // 判断JSON
    if (typeof str == 'string') {
        try {
            let obj = JSON.parse(str);
            if (typeof obj == 'object' && obj) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }
    return false
}

export async function trackCreate(route, identify) {  // 创建身份埋点 传入$route 和 类型
    if (!localStorage.getItem('userInfos')) return
    let userInfos = JSON.parse(localStorage.getItem('userInfos'))
    let path = '注册弹窗'
    if (route.query.point === 1) path = '无身份中心右侧'
    else if (route.query.point === 2) path = '查看技术动态创建身份'
    else if (route.query.point === 3) path = '专家页面弹窗'
    await trackingUser(JSON.stringify({ "memberId": userInfos.memberInfo.id, "path": path, "identify": identify, "source": "pc" }), "创建身份")
}

export async function checkTheme() {
    // 查询主题配色 未登录id为数字-1
    const isTPS = talentProjectSystemPage.find((path)=>{
        return window.location.href.includes(path)
    })
    const isPL = PostLineSite
    if(isTPS || G60site || isPL || TanSite || AISite) return []
    let result = []
    let [err, res] = await to(userPermission(store.getters.id, { fullPath: '/page/bigEvent' }))   // 黑白
    if (err) {
        console.error("check user permission error:", err);
        result.push(false)
    }
    else {
        if (!res) result.push(false)
        else result.push(res.data.show)
    }
    [err, res] = await to(userPermission(store.getters.id, { fullPath: '/page/newYearsUI' }))   // 新年
    if (err) {
        console.error("check user permission error:", err);
        result.push(false)
    }
    else {
        if (!res) result.push(false)
        else result.push(res.data.show)
    }
    return result
}

export function objectPropertyNumbers(obj) { // 返回对象属性个数
    return Object.getOwnPropertyNames(obj).length
}

export function encode(str) { // 加密
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
}
export function decode(str) { // 解密
    return decodeURIComponent(atob(str).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

export function delHtmlTag(str) { // 移除html标签
    return str.replace(/<[^>]+>/g, "");
}

export async function getIdentityTypes() {   // 获取身份列表
    let result = []
    let res = await getOrganizationTypes(1)
    result = res.data.list.filter(item => item.id + '' !== '500603')
    res = await getOrganizationTypes(0)
    if (res.data.list.length === 0) return result
    if (result.length >= 4) result = [...result.slice(0, 4), ...res.data.list.map((item) => {
        return {
            ...item,
            isLab: true // 产学研
        }
    }), ...result.slice(4)]
    else result = [...result, res.data.list[0]]
    let index = -1
    result.find((item,i)=>{
        if(item.des === '政府'){
            index = i
        }
        return item.des === '政府'
    })
    if(index !== -1){
        result.splice(3,0,result.splice(index,1)[0])
    }
    return result
}

export function openVipTipWindow() { // 打开VIP创建弹窗
    store.dispatch("message/hideIMWindow")
    switch (router.path) {
        case '/my-lab':
            // TODO_Track: 专家页面点击联络专家进入情况 e_come_vip_from_expert
            break;
        case '/my-lab-lab':
            // TODO_Track: 专家页面点击联络专家进入情况 e_come_vip_from_expert
            break;
        case '/user-center':
            // TODO_Track: 我的数字科创中心找企业进入情况 e_come_vip_from_searchBusiness
            break;
        default:
            break;
    }
    store.commit('message/SET_IM_WINDOW_DIALOG', true)
}

export function routerWindow(router, path, query) { // 在新标签页中打开页面
    const { href } = query ? router.resolve({
        path: path,
        query: query
    }) : router.resolve({
        path: path,
    })
    setTimeout(() => {
        window.open(href, "_blank");
    }, 200)
}

export function judgeDate(date, date1) { // 比较两个时间的大小
    let date2 = (date + '').split('-')
    date2 = Number(date2.map((item) => item > 9 ? '' + item : '0' + item).join(''))
    let date3 = (date1 + '').split('-')
    date3 = Number(date3.map((item) => item > 9 ? '' + item : '0' + item).join(''))
    if (date2 > date3) return -1
    else if (date2 === date3) return 0
    else return 1
}

export function setTimeDate(arr) { // 设置日期补0
    return arr.map((item) => item >= 10 ? item + '' : '0' + item).join('-')
}

export async function getUserRecommend() {   // 获取用户的推荐动态
    const [err, res] = await to(getlistHomepage(1, 10, recommendChannel))
    if (err) {
        console.error('获取推荐频道错误', err);
        return ''
    }
    return res
}

export function getResponseEmail(sender, receiver, time) {  // 获取回复邮件的html
    return `
    <p style="text-indent: 1em;"><span style="font-size: 14px;color: #999999;">----- 回复的原邮件 -----</span></p><p style="text-indent: 1em;"><span style="font-size: 14px;">发件人：</span><span style="color: #0F9FFD;font-size: 14px;">${sender}</span></p><p style="text-indent: 1em;"><span style="font-size: 14px;">收件人：${receiver}</span></p><p style="text-indent: 1em;"><span style="font-size: 14px;">日 期： ${time}</span></p>`
}

export function isHtml(str) {    // 判断是否html字符串
    return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(str);
}

export function aHrefLocation(direction){   // 返回a标签链接
    if(direction.length > 3 && direction.slice(-3) === 'id='){
        return 'https://www.keqidao.com'
    }
    return kqdPath + direction
}

export function aJointHref(direction,arr){   // 拼接类型的地址
    if(direction.length > 3 && direction.slice(-3) === 'id='){
        return 'https://www.keqidao.com'
    }
    const code = escape(arr.join('_'))
    return kqdPath + direction + '&typeInfo=' + code
}

export function insertScript(url, id = "") {  // 根据页面引入对应的script https://cdn.jsdelivr.net/npm/cos-js-sdk-v5/dist/cos-js-sdk-v5.min.js
    let script = document.createElement("script");
    script.src = url;
    if (id.length > 0) {
        script.id = id
    }
    document.getElementsByTagName("head")[0].appendChild(script);
}

export async function bannerClick(item) {
    if(!item.pathPc && !item.pathApp){
        // 不做任何处理
        return
    }
    let userInfo = localStorage.getItem("userInfo");
    // 1.8.2 未登录判断
    if (!userInfo) {
        ElMessage.warning("你尚未登录!");
        this.$router.push({
            path: "/login",
            query: { redirect: this.$route.fullPath },
        });
        return;
    }
    userInfo = JSON.parse(userInfo);
    if (item.pathPc.slice(1, 4) == "lab") {
        let lab = item.pathPc.slice(0, 8);
        let userID = item.pathPc.slice(8, 23);
        let identity = item.pathPc.slice(23);
        let res = await memberDetails(userID);
        item.pathPc = lab + res.data.organizationId + identity;
    }
    let path = "";
    let query = {};

    switch (item.pathTypeApp) {
        case 0: //不做任何处理
            break;
        case 1: //跳转站内页面
            // 跳转发布动态 /work-bench?showPurchaseDialog=1
            if (item.pathPc.indexOf("searchdetail") !== -1) {
                // 动态详情 或 需求详情
                let type = item.pathPc.indexOf("content");
                let tempPath = item.pathPc.slice(type + 8);
                let start = "";
                if (tempPath.charAt(0) === "0") {
                    start = tempPath.indexOf("dynamicId");
                    type = 0;
                } else {
                    start = tempPath.indexOf("publishId");
                    type = 1;
                }
                let Did = tempPath.slice(start + 10);
                try {
                    if (type === 0) await getDynamicDetail(Did);
                    // 获取动态详情
                    else await demandDetail(Did);
                } catch (error) {
                    if (type === 1) {
                        ElMessage.error("该动态已经删除");
                    } else {
                        ElMessage.error("该需求已经关闭");
                    }
                    return;
                }
            }
            if (item.pathPc.indexOf('/live-room?') !== -1) {  // 路演页面地址修改
                item.pathPc = item.pathPc.replace('/live-room', '/live')
            }
            if (item.pathPc.indexOf('/my-lab?') !== -1) {  // 个人实验室
                // let show = await viewExpertCheck(userInfo.id)
                // if (!show.data.show) {
                //     if(userInfo.isVip){
                //         // 是VIP
                //         ElMessage.warning('使用次数超限!')
                //         return
                //     }
                //     this.$store.dispatch("message/hideIMWindow")
                //     this.$store.commit('message/SET_IM_WINDOW_DIALOG', true)
                //     return
                // }
            }
            if (query.type) {
                // 完善组织机构信息
                routerWindow(this.$router, path, query)
            } else if (query.dynamic) {
                // 发布动态
                routerWindow(this.$router, path, query)
            } else {
                routerWindow(this.$router, "" + item.pathPc, query)
            }
            break;
        case 2: // 打开h5页面
            if (
                ["/company", "/government", "/industrial-garden"].includes(
                    item.pathPc
                )
            ) {
                // 解决方案页面判读
                routerWindow(this.$router, "" + item.pathPc, query)
                break;
            }
            window.open(item.pathPc);
            break;
        case 3: // 打开外部h5页面
            window.open(item.pathPc);
            break;
    }
}

export function getLocalUserId(){    // 获取缓存中的用户ID
    return localStorage.getItem('userInfos') ? JSON.parse(localStorage.getItem('userInfos')).memberInfo.id : false
}

export function stopMove(){ // 禁止滚动
    let m = function(e){e.preventDefault();};
    document.body.style.overflow='hidden';
    document.addEventListener("touchmove",m,{ passive:false });//禁止页面滑动
}

export function Move(){ // 开始滚动
    let m =function(e){e.preventDefault();};
    document.body.style.overflow='';//出现滚动条
    document.removeEventListener("touchmove",m,{ passive:true });
}

function getBrowserType() { // 获取浏览器类型
    // 获取浏览器 userAgent
    let ua = navigator.userAgent
    // 是否为 Opera
    let isOpera = ua.indexOf('Opera') > -1
    // 返回结果
    if (isOpera) { return 'Opera' }
    // 是否为 IE
    let isIE = (ua.indexOf('compatible') > -1) && (ua.indexOf('MSIE') > -1) && !isOpera
    let isIE11 = (ua.indexOf('Trident') > -1) && (ua.indexOf("rv:11.0") > -1)
    // 返回结果
    if (isIE11) { return 'IE11'
    } else if (isIE) {
        // 检测是否匹配
        let re = new RegExp('MSIE (\\d+\\.\\d+);')
        re.test(ua)
        // 获取版本
        let ver = parseFloat(RegExp["$1"])
        // 返回结果
        if (ver == 7) { return 'IE7'
        } else if (ver == 8) { return 'IE8'
        } else if (ver == 9) { return 'IE9'
        } else if (ver == 10) { return 'IE10'
        } else { return "IE" }
    }
    // 是否为 Edge
    let isEdge = ua.indexOf("Edge") > -1
    // 返回结果
    if (isEdge) { return 'Edge' }
    // 是否为 Firefox
    let isFirefox = ua.indexOf("Firefox") > -1
    // 返回结果
    if (isFirefox) { return 'Firefox' }
    // 是否为 Safari
    let isSafari = (ua.indexOf("Safari") > -1) && (ua.indexOf("Chrome") == -1)
    // 返回结果
    if (isSafari) { return "Safari" }
    // 是否为 Chrome
    let isChrome = (ua.indexOf("Chrome") > -1) && (ua.indexOf("Safari") > -1) && (ua.indexOf("Edge") == -1)
    // 返回结果
    if (isChrome) { return 'Chrome' }
    // 是否为 UC
    let isUC= ua.indexOf("UBrowser") > -1
    // 返回结果
    if (isUC) { return 'UC' }
    // 是否为 QQ
    let isQQ= ua.indexOf("QQBrowser") > -1
    // 返回结果
    if (isQQ) { return 'QQ' }
    // 都不是
    return ''
}

function generateUid() {    // 生成随机uid
    return 'kqd124-xxxxxxxx-xxxx-xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function generateCurrentTime(){  // 获取当前时间 yyyy-mm-dd hh:mm:ss
    let d = new Date();
    const month = (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)
    const day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate()
    // const hour = d.getHours() < 10 ? '0' + d.getHours() : d.getHours()
    // const minute = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()
    // const second = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds()
    let time =
        d.getFullYear() + "-" +
        month + "-" +
        day
        // + " " + hour + ":" +
        // minute + ":" +
        // second
    return time
}

function getOsInfo() {
    let userAgent = navigator.userAgent.toLowerCase();
    let name = '';
    let version = '';
    if (userAgent.indexOf('win') > -1) {
        name = 'Windows';
        if (userAgent.indexOf('windows nt 5.0') > -1) {
            version = 'Windows 2000';
        } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {
            version = 'Windows XP';
        } else if (userAgent.indexOf('windows nt 6.0') > -1) {
            version = 'Windows Vista';
        } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
            version = 'Windows 7';
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {
            version = 'Windows 8';
        } else if (userAgent.indexOf('windows nt 6.3') > -1) {
            version = 'Windows 8.1';
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {
            version = 'Windows 10';
        } else {
            version = '';
        }
    } else if (userAgent.indexOf('iphone') > -1) {
        name = 'Iphone';
    } else if (userAgent.indexOf('mac') > -1) {
        name = 'Mac';
    } else if (userAgent.indexOf('x11') > -1 || userAgent.indexOf('unix') > -1 || userAgent.indexOf('sunname') > -1 || userAgent.indexOf('bsd') > -1) {
        name = 'Unix';
    } else if (userAgent.indexOf('linux') > -1) {
        if (userAgent.indexOf('android') > -1) {
            name = 'Android';
        } else {
            name = 'Linux';
        }
    } else {
        name = '';
    }
    return version.length > 0 ? version : name
}

export function LaTrack(event_identification='',params={}){ // 51LA 埋点
    if(!window.LA || !event_identification) return
    let id = ''
    if(TanSite) id = getTanId()
    else if(AISite) id = getAIId()
    else{
        id = store.getters.id + '' !== '-1' || !store.getters.id ? store.getters.id : generateUid()
    }
    const customParams = {
        userid: id,
        time: generateCurrentTime(),
        appVersion: requestVersion,
        os: getOsInfo(),
        facture: getBrowserType(),
        ...params
    }
    window.LA.track(event_identification, customParams);
    console.log('LA track:',event_identification);
}

export function aTrack(str){ // a标签中的埋点
    LaTrack(str)
}

export function openFirstTip(){ // 是否打开第一次的弹窗
    let version = localStorage.getItem('LocalVersion')
    let show = false
    if(!version || JSON.parse(version) !== requestVersion){
        show = true
        localStorage.setItem('LocalVersion',JSON.stringify(requestVersion))
    }
    show && store.commit("message/SET_FIRST_WINDOW_VISIBLE",true) // 初次创建身份的弹窗
}

export const isFalse = (value) => !value;   // 判断null、undefined、0、false、NaN、''、false值


export const getMapNum = async () => {
    try {
        const res = await searchMapInfo()
        return res.data[0] || {}
    } catch (error) {
        console.error('获取数据出错',error);
        return {}
    }
}

export const setTanVipPop = (val) =>{
    store.dispatch("Tan/setVipShow",val)
}

export const objectArrayUnique = (arr, uniId) =>{
    let hash = {};
    return arr.reduce((accum, item) => {
        hash[item[uniId]] ? '' : hash[item[uniId]] = true && accum.push(item)
        return accum
    }, [])
}